import React, { Component } from 'react';
import './Aside.css';
import CloseBtn             from '../img/menu-close-icon.png';
import Filters              from './Filters/Filters';
import RouteFilters         from './Filters/RouteFilters';
import CategoryList         from './Filters/CategoryList';
import RightArrow           from '../img/keyboard-arrow-right.png';

class Aside extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'facets',
      category: ''
    };

    this.navigateBack = this.navigateBack.bind(this);
  }

  render() {
    // if the menu is not open then render null
    if (this.props.menuExpanded === false) {
      return null;
    }

    let asideClass = this.props.menuExpanded ? 'opened' : 'closed';
    let backButton = null;
    let content;

    if (this.state.type !== 'facets') {
      backButton = (
        <button
          className="navigate-back"
          onClick={ () => { this.navigateBack() } }>
          <img
            className="left-arrow"
            src={ RightArrow }
            alt="Return to previous window" />
          <span>Back</span>
        </button>
      );
    }

    switch(this.state.type) {
      case 'routes':
        content = (
          <RouteFilters
            apollo={ this.props.apollo }
            updateAppState={ this.props.updateAppState }
            navigateBack={ this.navigateBack }
            routeCollection={ this.props.routeCollection } />
        );  
        break;
      case 'category_list':
        content = (
          <CategoryList
            apollo={ this.props.apollo }
            updateFocus={ this.props.updateFocus }
            category={ this.state.category }
            updateAppState={ this.props.updateAppState }
            navigateBack={ this.navigateBack }
            routeCollection={ this.props.routeCollection } />
        )
        break;
      default:
        content = (
          <Filters
            updateAppState={ this.props.updateAppState }
            navigateBack={ this.navigateBack }
            filters={ this.props.filters } />
        );
    }

    return (
      <aside className={ asideClass }>
        <section>
          { backButton }
          <input
            className="close-icon2"
            type="image"
            alt="close button"
            src={ CloseBtn }
            onClick={ () => this.props.updateAppState('menuExpanded', false) } />
          <div className="lists">
            { content }
          </div>
        </section>
      </aside>
    );
  }

  navigateBack(type = 'facets', category) {
    this.setState({
      type: type,
      category: category
    });
  }
}

export default Aside;
