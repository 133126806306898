import React, { Component } from 'react';
import './LocationDescription.css';
import anchorme from "anchorme";

class LocationDescription extends Component {
  render() {
    return (
      <p id="locationDescription">
        {this.props.description &&
        this.props.description.split('\n').map((item, key) => {
          return (
            <React.Fragment key={key}>
              <span dangerouslySetInnerHTML={{__html: anchorme(item)}} />
              <br />
            </React.Fragment>
          );
        })}
      </p>
    );
  }
}

export default LocationDescription;