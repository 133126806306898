import React, { Component } from 'react';
import './Article.css';
import LocationRelatedPlacesIcons from './LocationRelatedPlacesIcons';
import ParkingData                from './Parking/Parking';
import LocationButtons            from './LocationButtons';
import LocationPhone              from './LocationPhone';
import LocationOpenStatus         from './LocationOpenStatus';
import LocationExpandable         from './LocationExpandable/LocationExpandable';
import Directions                 from './Directions/Directions';
import Share                      from './Share/Share';
import TranslocArrivalEstimates   from './TranslocArrivalEstimates/TranslocArrivalEstimates';
import SearchResults              from './SearchResults/SearchResults';

export default class Article extends Component {
  componentDidMount() {
    document.getElementById('location-details').focus();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.type !== this.props.type && prevProps.type === 'directions') {
      this.props.map.setPolylineOverlay([]);
    }
    document.getElementById('location-details').focus();
  }

  render() {
    let location_name,
        data = (
          <div>
            <div className="container">
              <p>No data to show</p>
            </div>
          </div>
        );

    if (this.props.type === 'details') {
      // Emergency phones have a special title
      if (this.props.properties.hasOwnProperty('category') && this.props.properties.category === 'Emergency Phone') {
        location_name = this.props.properties.category;
      }
      else {
        // everything else has the normal title
        location_name = this.props.properties.nameDisplay;
      }

      data = (
        <div>
          <div className="container">
            <h2>{ location_name }</h2>
            <address>{ this.props.properties.attrAddress }</address>
            <LocationRelatedPlacesIcons data={ this.props.properties } />
            <LocationButtons
              data={ this.props.properties.coordinates }
              updateType={ this.props.updateType } />
            <LocationPhone phoneNumber={ this.props.properties.attrPhone } />
            <LocationOpenStatus
              openStatusMessage={ this.props.properties.attrOpenMessage }
              openStatus={ this.props.properties.attrOpenNow } />
            <ParkingData
              apollo={ this.props.apollo }
              routeCollection={ this.props.routeCollection }
              updateFocus={ this.props.updateFocus }
              updateAppState={ this.props.updateAppState }
              data={ this.props.properties } />
          </div>
          <LocationExpandable
            updateFocus={ this.props.updateFocus }
            data={this.props.properties} />
        </div>
      );
    }

    // bus stop article
    else if (this.props.type === 'details-stop') {
      data = (
        <div>
          <div className="container">
            <h2>{ this.props.properties.stopName }</h2>
            <TranslocArrivalEstimates data={ this.props.properties } />
          </div>
        </div>
      );
    }

    // directions article, sub page of generic article
    else if (this.props.type === 'directions') {
      data = (
        <div>
          <div className="container">
            <h2>Directions</h2>
            <Directions
              apollo={ this.props.apollo }
              map={ this.props.map }
              nameDisplay={ this.props.properties.nameDisplay }
              coordinates={ this.props.properties.coordinates }
              address={ this.props.properties.attrAddress } />
          </div>
        </div>
      );
    }

    // share article, sub page of generic article
    else if (this.props.type === 'share') {
      data = (
        <div>
          <div className="container">
            <h2>Share this Location</h2>
            <Share />
          </div>
        </div>
      );
    }

    // share article, sub page of generic article
    else if (this.props.type === 'search-results') {
      data = (
        <SearchResults
          updateFocus={ this.props.updateFocus }
          annotationHoverAction={ this.props.annotationHoverAction }
          properties={ this.props.properties } />
      );
    }

    return (
      <article id="location-details" tabIndex="-1">
        { data }
      </article>
    );
  }
}
