import React, { Component } from 'react';
import './TranslocArrivalEstimates.css';
import NewSubscription      from '../../../models/subscriptions.js';
import { Subscription }     from 'react-apollo';
import moment               from 'moment-timezone';

class TranslocArrivalEstimates extends Component {
  render() {
    return (
      <Subscription
        subscription={ NewSubscription.subscribe_translocArrivalEstimates }
        variables={{ stops: this.props.data.stopId }}>
        {({ data, loading }) => {
          if (!loading) {
            if (data.translocArrivalEstimates.data.length > 0) {
              let arrivalsTimes,
                  arrivalRoutes = [],
                  groups = {};

              // first we need to group the arrivals by the routeId
              data.translocArrivalEstimates.data[0].arrivals.forEach((arrival, index) => {
                if (!groups.hasOwnProperty(arrival.routeId)) {
                  groups[arrival.routeId] = [arrival] ;
                }
                else {
                  groups[arrival.routeId].push(arrival);
                }
              });

              // next we need to sort the arrivals within each group
              for (let route in groups) {
                arrivalsTimes = groups[route].map((arrival, index) => {
                  // add the arrival data
                  return (
                    <span
                      key={ index }
                      className="arrival-time">
                      { moment(arrival.arrivalAt).fromNow() }
                    </span>
                  );
                });

                arrivalRoutes.push(
                  <div
                    key={ route }
                    className="arrival-route">
                    <div
                      style={ { borderLeft: '10px solid #'+groups[route][0].routeColor } }
                      className="container">
                      <h3>{ groups[route][0].routeName }</h3>
                      { arrivalsTimes }
                    </div>
                  </div>
                );
              }

              return (<div>{ arrivalRoutes }</div>);
            }
          }

          return (
            <p>No upcoming arrivals.</p>
          );
        }}
      </Subscription>
    );
  }
}

export default TranslocArrivalEstimates;
