import React, { Component } from 'react';
import './LocationButtons.css';
import DirectionsButton from '../../Buttons/DirectionsButton';
import ShareButton from '../../Buttons/ShareButton';

class LocationButtons extends Component {
  render() {
    return (
      <div>
        <DirectionsButton
          data={ this.props.data }
          updateType={ this.props.updateType } />
        <ShareButton updateType={ this.props.updateType } />
      </div>
    );
  }
}

export default LocationButtons;
