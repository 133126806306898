import React, { Component } from 'react';
import $ from "jquery";
import './Search.css';
import PerformSearch from '../models/graphql.js';
import iconSearch from './img/ic-search-black-24-px.png';
import menuHamburger from '../img/menu_icon.png';
import SearchResults from './SearchResults';

export default class Search extends Component {
  constructor(props) {
    super(props);

    // set the default properties of the application state
    this.state = {
      value:    this.props.queryText,
      results:  []
    };

    this.keyPress     = this.keyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.textQuery    = this.textQuery.bind(this);
    this.buttonClick  = this.buttonClick.bind(this);
    this.selectResult = this.selectResult.bind(this);

    // added a timeout to the search text box to reduce unneeded hits against
    // graphql until the user finishes typing their search string (a pause)
    this.searchTimeout = null;
  }

  componentDidMount() {
    $('#search-box').focusout(() => {
      // this.setState({results: []});
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.focus !== this.props.focus) {
      // an area was focused, lets clear the type ahead options
      this.setState({results: []});
    }
  }

  render() {
    return (
      <section id="search">
        <div id="search-box">
          <button
            className="menu"
            aria-haspopup="true"
            aria-expanded={ this.props.menuExpanded }
            onClick={ () => this.props.updateAppState('menuExpanded', true) }>
            <img src={ menuHamburger } alt="main menu button" />
          </button>
          <input
            type="search"
            id="site-search"
            name="q"
            autoComplete="off"
            value={ this.state.value }
            aria-label="type search term and hit enter key to submit"
            aria-owns="search-results"
            placeholder="Search..."
            onKeyUp={ this.keyPress }
            onChange={ this.handleChange } />
          <button
            className="search-mag" onClick={ this.buttonClick }>
            <img src={ iconSearch } alt="Search icon" />
          </button>
          <SearchResults
            focus={ this.props.focus }
            callback={ this.selectResult }
            results={ this.state.results } />
        </div>
      </section>
    );
  }

  /**
   * Capture any users that attempt to submit the input field with
   * the return key.
   *
   * @param {obj} e
   *   Key press event object.
   */
  keyPress(e) {
    if (e.keyCode === 13 && this.state.value.length > 2) {
      // to solve issues with fast typers, creating a delay so that enter
      // keyPresses that occur faster than the browers has a chance to keepup
      // with react rerendering
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(
        () => {
          // get some search results!
          this.buttonClick(e);
        }, 300
      );
    }
  }

  buttonClick(e) {
    if (this.state.value.length > 2) {
      // clear any active search results
      this.setState({
        results: [],
        value: this.state.value
      }, () => {
          this.textQuery('group');
        }
      );
    }
  }

  /**
   * The user has chosen a search result. Get the details for this
   * search result and focus in on coordinates for this location.
   *
   * @param {obj} e
   *   Click event object.
   */
  selectResult(e) {
    // collect the unique identifier for the location chosen
    let target = $(e.currentTarget);
    // clear the search results and show the overlay
    this.setState({
      results: [],
      value: target[0].innerText
    }, () => {
        this.props.updateFocus(target.data('optionId'));
      }
    );
  }

  /**
   * onChange event handler for the text field
   */
  handleChange(e) {
    // update the state of the component to match the users input
    this.setState(
      {value: e.target.value},
      // post setState functionality requirements
      () => {
        // setup a delay so that ajax responses do not overlap
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          // check to make sure three or more characters have been typed
          if (this.state.value.length > 2) {
            // look for search results
            this.textQuery();
          }
          else {
            // clear the results, user erased their search string
            this.setState({
              results: []
            }, () => {
              // clear the old search results
              this.props.showMarkerCollection('search', []);
            });
          }}, 500 // 500 milsecond delay to prevent ajax overlaps
        );
      }
    );
  }

  /**
   * Collects all locations for the map.
   */
  textQuery(type = '') {
    this.props.apollo.query({
      query: PerformSearch.string_search,
      variables: {
        search: this.state.value,
        rows: 100
      }
    }).then(result => {
      if (type === 'group') {
        this.props.showMarkerCollection('search', result.data.search.locations.records, true);
      }
      else {
        this.setState({ results: result.data.search.locations.records });
      }
    });
  }
}

