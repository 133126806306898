import React, { Component } from 'react';
import './DirectionsButton.css';

class DirectionsButton extends Component {
  render() {
    const openExternalLink = () => {
      if (this.props.hasOwnProperty('data') && this.props.data.length > 0) {
        window.location.href = "https://www.google.com/maps/dir/?api=1&destination=" + this.props.data;
      } else {
        window.location.href = "https://www.google.com/maps/dir///@36.0017824,-78.9589638,14z";
      }
    };

    return (
      <>
      <button
        id="directionButton"
        name="directions"
        onClick={ this.props.updateType }>Duke Maps Directions</button>
      <button
        id="directionButtonGoogle"
        name="directionsGoogle"
        onClick={ openExternalLink }>Open in Google Maps <i className="fa fa-external-link-alt" aria-hidden="true"></i></button>
      </>
    );
  }
}

export default DirectionsButton;