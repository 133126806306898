import React, { Component } from 'react';
import './LocationExpandableDescription.css';
import LocationImages from '../../LocationImages';
import LocationDescription from '../../LocationDescription';

export default class LocationExpandableDescription extends Component {
  render() {
    if (this.props.data !== undefined) {
      return (
        <div id="description" className={ this.props.expanded ? 'expanded' : 'collapsed' }>
          <div className="container">
            <ul className="list-decor-none">
              <LocationImages images={ this.props.data.media } />
              <LocationDescription description={ this.props.data.description } />
            </ul>
          </div>
        </div>
      );
    }

    return null;
  }
}
