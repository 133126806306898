import React, { Component } from 'react';
import './Overlay.css';
import PerformSearch        from '../models/graphql.js';
import Article              from './Article/Article';
import CloseBtn             from '../img/menu-close-icon.png';

class Overlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'details',
      overlay: {}
    };

    this.updateType                   = this.updateType.bind(this);
    this.clickHandler                 = this.clickHandler.bind(this);
    this.collectSpecificLocationById  = this.collectSpecificLocationById.bind(this);

  }

  componentDidMount() {
    // check to see of an overlay has been requested
    if (typeof this.props.focus !== 'undefined') {
      if (this.props.focus !== '') {
        this.collectSpecificLocationById(this.props.focus);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // check to see of an overlay has been requested
    if (typeof this.props.focus !== 'undefined') {
      // check for existing overlay and confirm we are not rerendering the same overlay
      if (prevProps.focus !== this.props.focus) {
        if (this.props.focus !== '' && this.props.focus !== null) {
          this.collectSpecificLocationById(this.props.focus);
        }
        else {
          if (this.props.searchResults.length > 0) {
            this.setState({
              type: 'search-results',
              overlay: { results: this.props.searchResults }
            });
          }
          else {
            this.setState({type: 'details', overlay: {}});
          }
        }
      }
    }

    if (this.props.searchResults !== prevProps.searchResults) {
      if (this.props.searchResults.length > 0) {
        this.setState({
          type: 'search-results',
          overlay: { results: this.props.searchResults }
        });
      }
    }

    // for mobile devices we need to track overlay moves
    if (this.props.isMobile) {
      /**
        * Collection of functions that build the mobile device element dragging feature.
        */
      var dragButton = document.querySelector("#drag-me"),
          dragItem = document.querySelector("#overlay"),
          active = false,
          currentY,
          initialY,
          maxHeight = null,
          yOffset = 0;

      if (dragButton !== null) {
        dragButton.addEventListener("touchstart", dragStart, false);
        dragButton.addEventListener("touchend", dragEnd, false);
        dragButton.addEventListener("touchmove", drag, false);
        dragButton.addEventListener("mousedown", dragStart, false);
        dragButton.addEventListener("mouseup", dragEnd, false);
        dragButton.addEventListener("mousemove", drag, false);
      }

      function dragStart(e) {
        if (e.type === "touchstart") {
          if (maxHeight === null) {
            // calculate the maxHeight the article can be raised too
            maxHeight = e.touches[0].clientY - 175;
          }
          initialY = e.touches[0].clientY - yOffset;
        } else {
          initialY = e.clientY - yOffset;
          if (maxHeight === null) {
            // calculate the maxHeight the article can be raised too
            maxHeight = e.clientY - 175;
          }
        }

        if (e.target === dragButton) {
          active = true;
        }
      }

      function dragEnd(e) {
        initialY = currentY;

        active = false;
      }

      function drag(e) {
        if (active) {
          e.preventDefault();

          if (e.type === "touchmove") {
            currentY = e.touches[0].clientY - initialY;
          } else {
            currentY = e.clientY - initialY;
          }

          yOffset = currentY;

          setTranslate(currentY, dragItem);
        }
      }

      function setTranslate(yPos, el) {
        if (yPos < -Math.abs(maxHeight)) {
          yPos = -Math.abs(maxHeight - 1);
        }
        else if (yPos > 0) {
          yPos = -1;
        }

        el.style.transform = "translate3d(0px, " + yPos + "px, 0)";
      }
    }
  }

  render() {
    let dragBtn = null;
    if (this.props.isMobile) {
      dragBtn = (<button key="drag" id="drag-me"><i className="fas fa-ellipsis-h"></i></button>);
    }

    if (Object.keys(this.state.overlay).length > 0) {
      return (
        <div id="overlay">
          <section>
            { dragBtn }
            <input
              className="close-icon2"
              type="image"
              alt="close button"
              src={ CloseBtn }
              onClick={ this.clickHandler } />
            <Article
              apollo={ this.props.apollo }
              updateFocus={ this.props.updateFocus }
              annotationHoverAction={ this.props.annotationHoverAction }
              map={ this.props.map }
              type={ this.state.type }
              updateType={ this.updateType }
              routeCollection={ this.props.routeCollection }
              updateAppState={ this.props.updateAppState }
              properties={ this.state.overlay } />
          </section>
        </div>
      );
    }

    return null;
  }

  /**
   * Update the state of the overlay to show Directions data.
   */
  updateType(e) {
    this.setState({type: e.target.name});
  }

  /**
   * Close the overlay or hide the directions panel.
   */
  clickHandler(e) {
    if (
      this.state.type === 'details' ||
      this.state.type === 'details-stop' ||
      this.state.type === 'search-results') {
      if (window.history.pushState) {
        window.history.pushState({path: window.location.pathname}, '', window.location.pathname);
        document.title = 'Duke University Maps';
      }
      this.setState({
        overlay: {}
      }, () => {
        this.props.updateFocus('');
      });
    }
    else {
      // user is attempting to close the directions box
      this.setState({type: 'details'});
    }
  }

  /**
   * Collect a specific search result byId.
   *
   * @param {string} e
   *   The unique identifier for a Duke search result.
   */
  collectSpecificLocationById(e) {
    if (e !== null) {
      var locationId = e.toString();
      // check to see if we are working with a transloc id
      if (locationId.startsWith('stop-')) {
        // perform the lookup query to get the bus stop name
        this.props.apollo.query({
          query:      PerformSearch.get_translocArrivalEstimates,
          variables:  { stops: locationId.slice(5) }
        }).then(result => {
          if (result.data && result.data.translocArrivalEstimates.data.length > 0) {
            this.setState({
              overlay: result.data.translocArrivalEstimates.data[0],
              type: 'details-stop'
            }, () => {
              // check to see if an overlay exists for the provided locationId
              // this.props.setSpecificBuildingOverlayIfExists(locationId, this.state.overlay);
            });
          }
        });
      }
      else {
        // this is a standard search query
        this.props.apollo.query({
          query:      PerformSearch.get_location,
          variables:  { byId: locationId }
        }).then(result => {
          if (result.data && result.data.search.locations.records.length === 1) {
            this.setState({
              overlay: result.data.search.locations.records[0],
              type: 'details'
            }, () => {
              // check to see if an overlay exists for the provided locationId
              this.props.setSpecificBuildingOverlayIfExists(locationId, this.state.overlay);
            });
          }
        });
      }
    }
  }
}

export default Overlay;
