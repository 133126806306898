import React, { Component } from 'react';
import                           './RouteFilters.css';
import PerformSearch        from '../../models/graphql.js';

class RouteFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      routes: this.props.hasOwnProperty('availableRoutes') ? this.props.availableRoutes:[]
    }

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.hasOwnProperty('availableRoutes')) {
      // translocRoutes
      this.props.apollo.query({
        query: PerformSearch.get_translocRoutes
      }).then(result => {
        if (result.data && result.data.translocRoutes.data.length > 0) {
          this.setState({
            routes: result.data.translocRoutes.data
          });
        }
      });
    }
  }

  render() {
    let routes = [];
    if (this.state.routes.length > 0) {
      this.state.routes.forEach((route, index) => {
        routes.push(
          <li key={ index }>
            <span style={ { backgroundColor: '#'+route.color } }></span>
            <div>
              <input
                type="checkbox"
                id={ route.routeId + "-checkbox" }
                checked={ this.props.routeCollection.includes(route.routeId) }
                onChange={ this.onChange }
                value={ route.routeId } /> 
                <label for={ route.routeId + "-checkbox" }>{ route.longName }</label>
            </div>
          </li>
        );
      });

      return (
        <div className="route-filters">
          <h2>Duke Bus Routes</h2>
          <ul>{ routes }</ul>
        </div>
      );
    }

    return (<p>No bus routing information available.</p>);
  }

  onChange(e) {
    let newCollection = [],
        newUrl;
    if (e.target.checked) {
      // add the routeId to the routeCollection to be shown on map
      newCollection.push(e.target.value);
      newCollection = newCollection.concat(this.props.routeCollection);
    }
    else {
      // remove the existing route
      newCollection = this.props.routeCollection.filter(id => id !== e.target.value);
    }

    // check to make sure we haven't already set the route param
    if (window.location.search.length > 0 && window.location.search.slice(1).split("&")[1] !== undefined) {
      // do nothing
    }
    else {
      if (this.props.hasOwnProperty('availableRoutes')) {
        newUrl = window.location.search + '&route=true';
        window.history.pushState({search: newUrl}, '', newUrl);
      }
    }

    // update the routeCollection
    this.props.updateAppState('routeCollection', newCollection);
  }
}

export default RouteFilters;
