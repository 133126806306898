import React, { Component } from 'react';
import './SearchResults.css';
import $ from "jquery";
// import PerformSearch from '../models/graphql.js';

export default class SearchResults extends Component {
  componentDidMount() {
    // when the user performs a search we need to move the focus to the
    // search results when the user presses one of the arrow keys
    $(window).keydown(function(e){
      // attempt to grab the search results list
      let li = $('#search-results li'),
          item,
          next;

      // confirm that the search field is in focus
      if ($('#site-search').is(':focus')) {
        // check to make sure that we have options to scroll through
        if (li.length > 0) {
          // check for the down arrow key
          if (e.which === 40) {
            // change the focus to the first search result
            li.eq(0).focus();
            e.preventDefault();
          }
        }
      } else {
        // check and make sure that we are working with a focused search result
        // the focus is already set on a list item (not sure which one)
        // check to make sure that we have options to scroll through
        if (li.length > 0) {
          // track down arrow clicks
          if (e.which === 40) {
            item = $(':focus');
            if (item.parent().length > 0) {
              if (item.parent()[0].id === 'search-results') {
                next = item.next();
                if (next.length > 0) {
                  // set the focus to the next list item
                  next.focus();
                  e.preventDefault();
                }
              }
            }
          }
          // track up arrow clicks
          else if (e.which === 38) {
            item = $(':focus');
            if (item.parent().length > 0) {
              if (item.parent()[0].id === 'search-results') {
                next = item.prev();
                if (next.length > 0) {
                  // set the focus to the next list item
                  next.focus();
                  e.preventDefault();
                }
              }
            }
          }
          // track enter/return key clicks
          else if (e.which === 13) {
            item = $(':focus');
            if (item.parent().length > 0) {
              if (item.parent()[0].id === 'search-results') {
                item.click();
              }
            }
          }
        }
      }
    });
  }

  componentDidUpdate(prevProps, props) {
    let results = $('#search-results li');
    if (results.length > 0) {
      // track mouse movements in and out of li items
      $('#search-results li').mouseover(function(){
        this.focus();
      });
    }
  }

  render() {
		if (this.props.results.length > 0) {
			let options = [],
					specialClass = '';

      // loop through the array and create the list item components
      for (let option in this.props.results) {
				specialClass = this.isEven(option) ? 'even' : 'odd';
				options.push(
					<li
						key={ option }
						id={ 'option-'+option }
            tabIndex="-1"
            role="option"
            aria-selected="false"
            className={ specialClass }
            onClick={ this.props.callback }
						data-option-id={ this.props.results[option].id }>
            { this.props.results[option].nameDisplay }
					</li>
				);
			}
			return (
				<ul id='search-results' role='listbox'>{ options }</ul>
			);
    }

		return (
      <ul id='search-results' className='hide-me' role='listbox'></ul>
    );
  }

	isEven(n) {
		return n % 2 === 0;
 	}
}

