import React, { Component }   from 'react';
import './Filters.css';
import EmergencyIcon        from '../../img/filter_icons/emergency-icon.png';
import EmergencyIconActive  from '../../img/filter_icons/emergency-icon-active.png';
import DukeCard             from '../../img/filter_icons/duke-card.png';
import DukeCardActive       from '../../img/filter_icons/duke-card-active.png';
import Venue                from '../../img/filter_icons/venue.png';
import VenueActive          from '../../img/filter_icons/venue-active.png';
import Ada                  from '../../img/filter_icons/ada.png';
// import AdaActive            from '../../img/filter_icons/ada-active.png';
import AdaRoutes            from '../../img/filter_icons/ada-routes.png';
import AdaRoutesActive      from '../../img/filter_icons/ada-routes-active.png';
import AdaEntrances         from '../../img/filter_icons/ada-entrances.png';
import AdaEntrancesActive   from '../../img/filter_icons/ada-entrances-active.png';
import Construction         from '../../img/filter_icons/construction.png';
import ConstructionActive   from '../../img/filter_icons/construction-active.png';
import Stores               from '../../img/filter_icons/stores.png';
import StoresActive         from '../../img/filter_icons/stores-active.png';
import Food                 from '../../img/filter_icons/food.png';
import FoodActive           from '../../img/filter_icons/food-active.png';
import Lactation            from '../../img/filter_icons/lactation.png';
import LactationActive      from '../../img/filter_icons/lactation-active.png';
import Residential          from '../../img/filter_icons/residential.png';
import ResidentialActive    from '../../img/filter_icons/residential-active.png';
import Athletics            from '../../img/filter_icons/athletic.png';
import AthleticsActive      from '../../img/filter_icons/athletic-active.png';
import Medical              from '../../img/filter_icons/medical.png';
import MedicalActive        from '../../img/filter_icons/medical-active.png';
import Academic             from '../../img/filter_icons/academic.png';
import AcademicActive       from '../../img/filter_icons/academic-active.png';
import Bus                  from '../../img/filter_icons/bus.png';
import ParkingVisitor       from '../../img/filter_icons/parking.png';
import ParkingVisitorActive from '../../img/filter_icons/parking-active.png';
import ParkingMixed         from '../../img/filter_icons/parking-mixed.png';
import ParkingPermitActive  from '../../img/filter_icons/parking-permit-active-1.png';
import ParkingMotorcycle    from '../../img/filter_icons/motorcycle-parking.png';
import ParkingMotorcycleActive from '../../img/filter_icons/motorcycle-parking-active.png';
import ParkingBicycle       from '../../img/filter_icons/bicycle-parking.png';
import ParkingBicycleActive from '../../img/filter_icons/bicycle-parking-active.png';
import RightArrow           from '../../img/keyboard-arrow-right.png';

class Filters extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.getElementById('menu-focus').focus();
  }

  static FILTER_MENU() {
    return [
      {
        'id': 'ACADEMIC_ADMINISTRATIVE',
        'label': 'Academic',
        'icon_active': AcademicActive,
        'icon_inactive': Academic
      },
      {
        'id': 'ADA_EXPANDED',
        'label': 'ADA Accessibility',
        'icon_active': Ada,
        'icon_inactive': Ada,
        'alt': 'Expand ADA options',
        'sub_menu': [
          {
            'id': 'ADA_ENTRANCES',
            'label': 'ADA - Entrances',
            'icon_active': AdaEntrancesActive,
            'icon_inactive': AdaEntrances
          },
          {
            'id': 'ADA_ROUTES',
            'label': 'ADA - Routes',
            'icon_active': AdaRoutesActive,
            'icon_inactive': AdaRoutes
          }
        ]
      },
      {
        'id': 'ATHLETICS',
        'label': 'Athletic',
        'icon_active': AthleticsActive,
        'icon_inactive': Athletics
      },
      {
        'id': "BUS",
        'label': 'Bus Routes',
        'icon_active': Bus,
        'icon_inactive': Bus
      },
      {
        'id': 'DINING',
        'label': 'Dining',
        'icon_active': FoodActive,
        'icon_inactive': Food
      },
      {
        'id': 'DUKE_CARD',
        'label': 'DukeCard',
        'icon_active': DukeCardActive,
        'icon_inactive': DukeCard
      },
      {
        'id': 'EMERGENCY_PHONE',
        'label': 'Emergency Call Box',
        'icon_active': EmergencyIconActive,
        'icon_inactive': EmergencyIcon
      },
      {
        'id': 'LACTATION_ROOM',
        'label': 'Lactation Room',
        'icon_active': LactationActive,
        'icon_inactive': Lactation
      },
      {
        'id': 'MEDICAL_CENTER',
        'label': 'Medical',
        'icon_active': MedicalActive,
        'icon_inactive': Medical
      },
      {
        'id': 'PARKING_EXPANDED',
        'label': 'Parking',
        'icon_active': ParkingMixed,
        'icon_inactive': ParkingMixed,
        'sub_menu': [
          {
            'id': 'VISITOR_PARKING',
            'label': 'Visitor',
            'icon_active': ParkingVisitorActive,
            'icon_inactive': ParkingVisitor
          },
          {
            'id': 'PERMIT_PARKING',
            'label': 'Permit',
            'icon_active': ParkingPermitActive,
            'icon_inactive': ParkingVisitor
          },
          {
            'id': 'MOTORCYCLES_PARKING',
            'label': 'Motorcycle',
            'icon_active': ParkingMotorcycleActive,
            'icon_inactive': ParkingMotorcycle
          },
          {
            'id': 'BICYCLES_PARKING',
            'label': 'Bicycle',
            'icon_active': ParkingBicycleActive,
            'icon_inactive': ParkingBicycle
          }
        ]
      },
      {
        'id': 'HOUSING',
        'label': 'Residential',
        'icon_active': ResidentialActive,
        'icon_inactive': Residential
      },
      {
        'id': 'DUKE_STORES',
        'label': 'Stores',
        'icon_active': StoresActive,
        'icon_inactive': Stores
      },
      {
        'id': 'UNDER_CONSTRUCTION',
        'label': 'Under Construction',
        'icon_active': ConstructionActive,
        'icon_inactive': Construction
      },
      {
        'id': 'PERFORMANCE_VENUES',
        'label': 'Venues',
        'icon_active': VenueActive,
        'icon_inactive': Venue
      }
    ];
  }

  /**
   * The filter list is found in the App.js file as a state of app component.
   */
  render() {

    const handleKeyDown = event => {
      if (event.key === 'Enter') {
        event.target.checked = !event.target.checked;
        this.updateFilters(event.target.getAttribute('data-id'));
      }
    };

    return (
      <div id="menu-focus" tabIndex="-1">
        <div className="menu-filters">
          <ul>
            { Filters.FILTER_MENU().map((item, index) => (
              <li key={ index }>
                { (item.sub_menu)
                  ? <>
                    <div className="heading">
                      <img src={ this.props.filters[item.id] ? item.icon_active : item.icon_inactive } alt="" />
                      <span>{ item.label }</span>
                    </div>
                    <ul className="shown">
                      { item.sub_menu.map((sub_item, index) => (
                        <li key={ index }>
                          <button
                            tabIndex="0"
                            aria-expanded="false"
                            aria-label={ "Show " + sub_item.label + " category as a list" }
                            onClick={ () => { this.props.navigateBack('category_list', sub_item.id) } }
                            disabled={ sub_item.id === 'ADA_ROUTES' ? true:false }
                            className={ this.props.filters[sub_item.id] ? 'active' : '' }>
                            <div>
                              <img src={ this.props.filters[sub_item.id] ? sub_item.icon_active : sub_item.icon_inactive } alt="" />
                            </div>
                            <span>{ sub_item.label }</span>
                            {sub_item.id !== 'ADA_ROUTES' &&
                              <span>
                                <i className="fa fa-ellipsis-v right-arrow"></i>
                              </span>
                            }
                          </button>
                          <input 
                            onClick={ () => { this.updateFilters(sub_item.id) } }
                            onKeyDown={ handleKeyDown }
                            data-id={ sub_item.id }
                            aria-label={ "Toggle " + sub_item.label + " category on map" }
                            aria-checked={ this.props.filters[sub_item.id] ? "true":"false" }
                            type="checkbox"
                            tabIndex="0"
                            defaultChecked={ this.props.filters[sub_item.id] ? true:false }>
                          </input>
                        </li>
                      ))}
                    </ul>
                    </>
                  : <>
                    <button
                      tabIndex="0"
                      aria-label={ item.sub_menu ? "Show nested filter options":"Show " + item.label + " category as a list" }
                      aria-expanded="false"
                      onClick={ () => { item.id === 'BUS' ? this.props.navigateBack('routes') : this.props.navigateBack('category_list', item.id) }}
                      className={ this.props.filters[item.id] ? 'active' : '' }>
                      <div>
                        <img src={ this.props.filters[item.id] ? item.icon_active : item.icon_inactive } alt="" />
                      </div>
                      <span>{ item.label }</span>
                      <span>
                        <i className="fa fa-ellipsis-v right-arrow"></i>
                      </span>
                    </button>
                    {item.id !== 'BUS' &&
                      <input 
                        onChange={ () => { this.updateFilters(item.id) }}
                        onKeyDown={ handleKeyDown }
                        data-id={ item.id }
                        aria-label={ "Toggle " + item.label + " category on map" }
                        aria-checked={ this.props.filters[item.id] ? "true":"false" }
                        type="checkbox"
                        tabIndex="0"
                        defaultChecked={ this.props.filters[item.id] ? true:false }>
                      </input>
                    }
                    </>
                }
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  updateFilters(filterType) {
    // update the filters
    this.props.updateAppState('filters', filterType);
  }
}

export default Filters;
