import React, { Component } from 'react';
import $                    from 'jquery';
import logo                 from './img/campus-map-logo.png';
import './App.css';
import Map                  from './AppleMap/Map';
import Search               from './Search/Search';
import Aside                from './Aside/Aside';
import MobileAppCTA         from './Overlay/MobileAppCTA/MobileAppCTA';
import Print                from './Print';

import { ApolloProvider }               from "react-apollo";
import { ApolloClient }                 from 'apollo-client';
import { InMemoryCache }                from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink }                     from 'apollo-link-http';
// import { onError }                      from 'apollo-link-error';
// import { ApolloLink, split }            from 'apollo-link';
import { split }            from 'apollo-link';
import introspectionQueryResultData     from './models/fragmentTypes.json';

import * as AbsintheSocket          from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { Socket as PhoenixSocket }  from "phoenix";
// import { getMainDefinition }        from 'apollo-utilities';
import { hasSubscription }          from "@jumpn/utils-graphql";

import Redirects                    from "./Redirects";

class App extends Component {
  constructor(props) {
    super(props);

    // set the default properties of the application state
    this.state = {
      mobileCTA:        false,
      menuExpanded:     false,
      queryText:        '',
      overlays:         {},
      searchResults:    [],
      isMobile:         $(document).width() < 768 ? true : false,
      routeCollection:  [],
      markerCollection: {
        search:                   [],
        EMERGENCY_PHONE:          [],
        LACTATION_ROOM:           [],
        DINING:                   [],
        DUKE_CARD:                [],
        DUKE_STORES:              [],
        ATHLETICS:                [],
        ACADEMIC_ADMINISTRATIVE:  [],
        RESIDENTIAL:              [],
        MEDICAL_CENTER:           [],
        PERFORMANCE_VENUES:       [],
        TRANSLOC_STOPS:           [],
        VISITOR_PARKING:          [],
        PERMIT_PARKING:           [],
        UNDER_CONSTRUCTION:       [],
        ADA_ENTRANCES:            [],
        ADA_ROUTES:               []
      },
      filters:          {
        EMERGENCY_PHONE:          false,
        LACTATION_ROOM:           false,
        DINING:                   false,
        DUKE_CARD:                false,
        DUKE_STORES:              false,
        ACADEMIC_ADMINISTRATIVE:  false,
        RESIDENTIAL:              false,
        MEDICAL_CENTER:           false,
        ATHLETICS:                false,
        PERFORMANCE_VENUES:       false,
        VISITOR_PARKING:          false,
        PERMIT_PARKING:           false,
        UNDER_CONSTRUCTION:       false,
        ADA_ENTRANCES:            false,
        ADA_ROUTES:               false
      }
    };

    let fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData
    });

    // connect to apollo
    this.apollo = new ApolloClient({
      link: split(
        operation => hasSubscription(operation.query),
        createAbsintheSocketLink(AbsintheSocket.create(
          new PhoenixSocket(
            process.env.REACT_APP_WSS_URI,
            { params: { 'apikey': process.env.REACT_APP_API_KEY } }
          )
        )),
        new HttpLink({
          uri:          process.env.REACT_APP_URI,
          credentials:  'same-origin',
          headers:      { 'apikey': process.env.REACT_APP_API_KEY }
        }),
      ),
      cache: new InMemoryCache({ fragmentMatcher })
    });

    // js binding of this context
    this.updateFocus            = this.updateFocus.bind(this);
    this.updateOverlays         = this.updateOverlays.bind(this);
    this.showMarkerCollection   = this.showMarkerCollection.bind(this);
    this.updateAppState         = this.updateAppState.bind(this);
  }

  componentDidMount() {
    // check for old links to route to new links
    (new Redirects()).routeRedirects();
    // check to see if the user is on a mobile device
    if (this.state.isMobile) {
      // check to see if the mobile cookie has been set
      if (document.cookie.indexOf('duke_map_mobile=') === -1) {
        document.cookie = 'duke_map_mobile=true';
        // show the mobile app cta if the user has not seen it before
        this.setState({ mobileCTA: true });
      }
    }

    // detect screen size changes and rebuild the components if the screen size
    // changes to a mobile device size, improves responsive behavior
    $(window).resize(() => {
      // collect the new width from the screen size change
      let newWidth = $(document).width() < 768 ? true : false;
      // check to see if old width is different than the new width
      if (this.state.isMobile !== newWidth) {
        this.setState({ isMobile: newWidth });
      }
    });
  }

  render() {
    let params = new URLSearchParams(window.location.search).get('focus');

    return (
      <ApolloProvider client={ this.apollo }>
        <div className="App">
          <header className="App-header">
            <div className="header-left">
              <h1 className="sr-only">Duke Campus Maps</h1>
              <img src={ logo } className="logo" alt="logo" />
            </div>
            <div className="header-right">
              <nav className="menu">
                <a
                  href="https://www.duke.edu"
                  rel="noopener noreferrer">DUKE UNIVERSITY</a> |
                <a href="mailto:mapsteam@duke.edu">FEEDBACK</a> |
                <Print isMobile={ this.state.isMobile } />
              </nav>
            </div>
          </header>
          <main role="main">
            <Search
              apollo={ this.apollo }
              focus={ params }
              queryText={ this.state.queryText }
              updateFocus={ this.updateFocus }
              showMarkerCollection={ this.showMarkerCollection }
              showSearchGroup={ this.showSearchGroup }
              menuExpanded={ this.state.menuExpanded }
              updateAppState={ this.updateAppState } />
            <Map
              apollo={ this.apollo }
              isMobile={ this.state.isMobile }
              updateFocus={ this.updateFocus }
              focus={ params }
              filters={ this.state.filters }
              searchResults={ this.state.searchResults }
              routeCollection={ this.state.routeCollection }
              markerCollection={ this.state.markerCollection }
              showMarkerCollection={ this.showMarkerCollection }
              overlays={ this.state.overlays }
              updateAppState={ this.updateAppState }
              updateOverlays={ this.updateOverlays }
              setFilterPath={ this.setFilterPath } />
          </main>
          <Aside
            apollo={ this.apollo }
            updateFocus={ this.updateFocus }
            updateAppState={ this.updateAppState }
            filters={ this.state.filters }
            routeCollection={ this.state.routeCollection }
            menuExpanded={ this.state.menuExpanded } />
          <footer>
            <MobileAppCTA
              isMobile={ this.state.isMobile }
              showMobileCTA={ this.state.mobileCTA }
              updateAppState={ this.updateAppState } />
          </footer>
        </div>
      </ApolloProvider>
    );
  }

  /**
   * Generic helper function for keeping the app state.
   *
   * @param  string property
   *   The specific property to modify.
   * @param  bool|string|object|array state
   *   The value to be assigned to the property.
   */
  updateAppState(property, state) {
    // react batches state changes, this means that statechanges dependent on
    // prevstate must be executied in the following way
    if (property === 'filters') {
      this.setState((prevState) => {
        let filterCollection = Object.assign({}, prevState.filters);
        filterCollection[state] = !prevState.filters[state];
        return ({
          filters: filterCollection
        });
      });
    } else if (property === 'deep_link_filters') {
      this.setState((prevState) => {
        let filterCollection = Object.assign({}, prevState.filters);
        state.forEach(element => {
          filterCollection[element] = !prevState.filters[element];
        });
        return ({
          filters: filterCollection
        });
      });
    }
    else {
      this.setState({[property]: state});
    }
  }

  /**
   * Updates the state of the application to display an overlay with the clicked
   * buildings meta data.
   *
   * @param  {string} locationId
   *   The locations unique identifier.
   */
  updateFocus(locationId) {
    if (locationId !== '') {
      this.setState({
        menuExpanded: false,
      },
      () => {
          this.updateBrowserUrl(locationId);
        }
      );
    }
    else {
      this.updateBrowserUrl(locationId);
    }
  }

  showMarkerCollection(type, locations, showOverlay = false) {
    // call set state with access to prevprops
    this.setState(prevState => {
      let newMarkerCollection = Object.assign({}, prevState.markerCollection);
      newMarkerCollection[type] = locations;

      // show search results in the overlay
      if (showOverlay) {
        return {
          searchResults: locations,
          markerCollection: newMarkerCollection
        };
      }

      return {
        markerCollection: newMarkerCollection
      };
    }, () => {
      if (window.location.search.length > 0 && window.location.search.slice(1).split("&")[1] !== undefined) {
        // this captures the one scenario when we want the overlay to maintain
        // focus when selecting to show new filter options for the map (bus routes)
      }
      else {
        // clear the browser/focus
        this.updateBrowserUrl('');
      }
    });
  }

  updateOverlays(results) {
    var newResults = results.map(function(result){
      if (result.geojson.geometry !== null) {
        result.geojson.geometry.coordinates = JSON.parse(result.geojson.geometry.coordinates);

        return result;
      }
    });

    this.setState({ overlays: newResults });
  }

  updateBrowserUrl(locationId) {
    if (window.history.pushState) {
      if (locationId === '') {
        let filters = this.state.filters
        this.setFilterPath(filters)
      }
      else {
        let newUrl = window.location.pathname + '?focus=' + locationId;
        window.history.pushState({path: newUrl}, '', newUrl);
        if (this.state.searchResults.length > 0) {
          this.setState({searchResults: []});
        }
      }
      this.forceUpdate();

      // @todo - update the page title to be the location nameDisplay field
      // document.title = location.nameDisplay.substr(0,1).toUpperCase() + location.nameDisplay.substr(1) + ' | Duke University Maps';
    }
  }

  setFilterPath(filters) {
    let filter_names = Object.keys(filters).filter(function (key) { return filters[key] === true });
    if (filter_names.length > 0) {
      let newUrl = window.location.pathname + '?show=' + filter_names.join(',');
      window.history.pushState({ path: newUrl }, '', newUrl);
    } else {
      let newUrl = window.location.pathname
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }
}

export default App;
