import React, { Component } from 'react';
import './LocationRelatedPlacesIcons.css';
import buildingInformation from '../../img/info_button.png';
import dining from '../../img/food_button.png';
import dukeStores from '../../img/stores_button.png';
// import dukeCard from '../../img/card_button.png';
import dukeGardens from '../../img/gardens_button.png';
import theLink from '../../img/link_button.png';

export default class LocationRelatedPlacesIcons extends Component {
  render() {
    // make sure that the area in focus has relatedplaces
    if (this.props.data.relatedPlaces !== undefined) {
      // build the icon components that will be rendered
      let icons = this.createIcons();
      return (
        <div id="building-icon">
          { icons }
        </div>
      );
    }

    return null;
  }

  /**
   * Creates the location buttons that represent the related places for a
   * specific location being viewed.
   *
   * @return array
   *   Returns an array of React components that are images to be rendered.
   */
  createIcons() {
    if (this.props.data.relatedPlaces[0].formatFlat !== undefined) {
      let icons       = [],
          categories  = {};

      // generate a list of categories
      this.props.data.relatedPlaces[0].formatFlat.reverse().map( (place) => {
        categories[place.category] = place.category;

        return null;
      });
      // loop through the categories and create the image components
      for (let category in categories) {
        switch(categories[category]) {
          case 'Building Information':
            icons.push(
              <img
                key={ categories[category] }
                src={ buildingInformation }
                alt={ categories[category] } />
            );
            break;
          case 'Dining':
            icons.push(
              <img
                key={ categories[category] }
                src={ dining }
                alt={ categories[category] } />
            );
            break;
          case 'Duke Stores':
            icons.push(
              <img
                key={ categories[category] }
                src={ dukeStores }
                alt={ categories[category] } />
            );
            break;
          case 'Duke Gardens':
            icons.push(
              <img
                key={ categories[category] }
                src={ dukeGardens }
                alt={ categories[category] } />
            );
            break;
          case 'The Link':
            icons.push(
              <img
                key={ categories[category] }
                src={ theLink }
                alt={ categories[category] } />
            );
            break;
          default:
            break;
        }
      }

      return icons;
    }
  }
}
