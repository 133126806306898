import React, { Component } from 'react';
import styles from './Parking.module.css';
import RouteFilters from '../../../Aside/Filters/RouteFilters';

export default class Parking extends Component {
  render() {
    let data = [];

    if (this.props.data.hasOwnProperty('attrValidPermit')) {
      data.push(this.attrValidPermit(this.props.data.attrValidPermit));
    }
    if (this.props.data.hasOwnProperty('attrLotPermit')) {
      data.push(this.attrLotPermit(this.props.data.attrLotPermit));
    }
    if (this.props.data.hasOwnProperty('attrLotVisitor')) {
      data.push(this.attrLotVisitor(this.props.data.attrLotVisitor));
    }
    if (this.props.data.hasOwnProperty('attrNote')) {
      data.push(this.attrNote(this.props.data.attrNote));
    }
    if (this.props.data.hasOwnProperty('attrAfterHourAccess')) {
      data.push(this.attrAfterHourAccess(this.props.data.attrAfterHourAccess));
    }
    if (this.props.data.hasOwnProperty('attrBusRoutes')) {
      data.push(this.attrBusRoutes(this.props.data.attrBusRoutes));
    }

    if (data.length > 0) {
      return (<> { data } </>);
    }

    return null;
  }

  attrValidPermit(data) {
    if (data !== null) {
      return (
        <p key="1"><em>Valid Permit:</em> { data }</p>
      );
    }

    return null;
  }

  attrLotPermit(data) {
    if (data !== null) {
      data = data === 'Y' ? 'Yes':'No';

      return (
        <p key="2"><em>Permit:</em> { data }</p>
      );
    }
  }

  attrLotVisitor(data) {
    if (data !== null) {
      data = data === 'Y' ? 'Yes':'No';

      return (
        <p key="3"><em>Visitor:</em> { data }</p>
      );
    }
  }

  attrNote(data) {
    if (data !== null) {
      return (
        <p key="4"><em>Note:</em> { data }</p>
      );
    }
  }

  attrAfterHourAccess(data) {
    if (data !== null) {
      return (
        <p key="5"><em>After Hour Access:</em> { data }</p>
      );
    }
  }

  attrBusRoutes(data) {
    if (data !== null && data.length > 0) {
      return (
        <React.Fragment key="6">
          <RouteFilters
            apollo={ this.props.apollo }
            updateFocus={ this.props.updateFocus }
            updateAppState={ this.props.updateAppState }
            availableRoutes={ this.props.data.attrBusRoutes }
            routeCollection={ this.props.routeCollection } />
        </React.Fragment>
      );
    }
  }
}
