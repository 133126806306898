import React, { Component }   from 'react';
import DirectionsSearchField  from './DirectionsSearchField';
import './Directions.css';
import polyUtil               from 'polyline-encoded';
import DirectionsResults      from './DirectionsResults';
import SwapImg                from '../../../img/swap-vert-24-px.png';
import BikeImg                from '../../../img/directions-bike.png';
import BikeImgActive          from '../../../img/directions-bike-active.png';
import WalkImg                from '../../../img/directions-walk.png';
import WalkImgActive          from '../../../img/directions-walk-active.png';
import CarImg                 from '../../../img/directions-car.png';
import CarImgActive           from '../../../img/directions-car-active.png';

class Directions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start:        { value: '', label: '', latLng: {} },
      end:          { value: '', label: this.props.nameDisplay, latLng: {}},
      type:         'car',
      active_field: 'start',
      cordinates:   {},
      directions:   [],
      duration:     0,
      distance:     0
    };

    // added a timeout to the search text box to reduce unneeded hits against
    // graphql until the user finishes typing their search string (a pause)
    this.searchTimeout = null;

    this.typeActivate = this.typeActivate.bind(this);
    this.swapClick    = this.swapClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.callOTP      = this.callOpenTripPlanner.bind(this);
  }

  render() {
    return (
      <div id="directions">
        <form id="directions-form" onSubmit={ this.callOpenTripPlanner }>
          <button
            className="swap-img"
            type="button"
            onClick={ this.swapClick }>
            <img src={ SwapImg } alt="Swap start and end locations." />
          </button>
          <div id="directions" className="inputs">
            <label>
              <span>Start:</span>
              <DirectionsSearchField
                elId="start"
                value={ this.state.start.label }
                aLabel="Starting address"
                disabled={ this.state.active_field === 'start'  ? false : true }
                active_field={ this.state.active_field }
                onChange={ this.handleChange }
                apollo={ this.props.apollo } />
            </label>
            <label>
              <span>End:</span>
              <DirectionsSearchField
                elId="end"
                value={ this.state.end.label }
                aLabel="Ending address"
                disabled={ this.state.active_field === 'end' ? false : true }
                active_field={ this.state.active_field }
                onChange={ this.handleChange }
                apollo={ this.props.apollo } />
            </label>
          </div>
          <div>
            <button
              className="direction-type"
              id="car"
              type="button"
              onClick={ this.typeActivate }>
              <img
                src={ this.state.type === 'car' ? CarImgActive : CarImg }
                alt="Collect car directions." />
            </button>
            <button
              className="direction-type"
              id="bicycle"
              type="button"
              onClick={ this.typeActivate }>
              <img
                src={ this.state.type === 'bicycle' ? BikeImgActive : BikeImg }
                alt="Collect bicycle directions." />
            </button>
            <button
              className="direction-type"
              id="walk"
              type="button"
              onClick={ this.typeActivate }>
              <img
                src={ this.state.type === 'walk' ? WalkImgActive : WalkImg }
                alt="Collect walking directions." />
            </button>
          </div>
        </form>
        <DirectionsResults
          duration={ this.state.duration }
          distance={ this.state.distance }
          type={ this.state.type }
          active_field={ this.state.active_field }
          name={ this.props.nameDisplay }
          address={ this.props.address }
          directions={ this.state.directions } />
      </div>
    );
  }

  /**
   * Update the direction type the user has requested.
   */
  typeActivate(e) { 
    const getId = (e) => e.target.parentElement.id === "" ? e.target.id : e.target.parentElement.id;
    this.setState({
      type: getId(e)
    },
    () => {
      // check to see if we already have directions
      if (Object.keys(this.state[this.state.active_field]['latLng']).length > 0) {
        // look up the new directions for this type
        this.callOpenTripPlanner(this.state[this.state.active_field]['latLng']);
      }
    });
  }

  /**
   * This will swap the start and end locations for the form.
   */
  swapClick(e) {
    this.setState((state, props) => {
      return {
        start:        state.end,
        end:          state.start,
        active_field: state.active_field === 'start' ? 'end' : 'start'
      };
    },
    () => {
      // check to see if we already have directions
      if (Object.keys(this.state[this.state.active_field]['latLng']).length > 0) {
        // look up the new directions for this type
        this.callOpenTripPlanner(this.state[this.state.active_field]['latLng']);
      }
    });
  }

  /**
   * Updates an input fields state to reflect what the user typed into the field.
   */
  handleChange(field, location) {
    if (location !== null) {
      // make sure location is structured as an object
      if (typeof location === 'string') {
        location = { value: location, label: location, __isNew__: true };
      }
      // check to see if the user is searching for an address
      if (location.hasOwnProperty('__isNew__') === true) {
        // lookup some coordinates
        this.getCoordinates(location.value).then(coords => {
          this.setState(
            { [this.state.active_field]: { value: location.value, label: location.value, latLng: coords } },
            () => {
              if (coords !== null) {
                // get the directions
                this.callOpenTripPlanner(coords);
              }
            }
          );
        });
      }
      else {
        // use the existing lat/lng for the searched for location
        this.setState(
          { [field]: { value: location.value, label: location.label, latLng: location.latLng } },
          () => {
            // setup a delay so that ajax responses do not overlap
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
              this.callOpenTripPlanner(this.state[this.state.active_field]['latLng']);
              }, 200 // 400 milsecond delay to prevent ajax overlaps
            );
          }
        );
      }
    }
  }

  getCoordinates(address) {
    return new Promise((resolve, reject) => {
      this.setState(
        {directions: []},
        () => {
          if (this.props.map !== null) {
            (new this.props.map.mapkit.Geocoder({
              language: "en-GB",
              getsUserLocation: false
            })).lookup(address, (error, data) => {
              if (error !== null) {
                reject("Failed to lookup cordinates for this address.");
              }
              else {
                if (data.results.length > 0) {
                  // get the directions
                  resolve(data.results[0]['coordinate']);
                }
              }
            });
          }
        }
      );
    });
  }

  callOpenTripPlanner(cordinates) {
    let query = { mode: this.state.type.toUpperCase() };
    // set coord variables
    if (this.state.active_field === 'start') {
      query.fromPlace = cordinates.latitude + ',' + cordinates.longitude;
      query.toPlace = this.props.coordinates;
    }
    else {
      query.toPlace = cordinates.latitude + ',' + cordinates.longitude;
      query.fromPlace = this.props.coordinates;
    }

    const request = new Request(
      process.env.REACT_APP_OTP_URI + 'otp/routers/default/plan?toPlace=' + query.toPlace + '&fromPlace=' + query.fromPlace + '&mode=' + query.mode,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'apikey': process.env.REACT_APP_OTP_API_KEY

        })
      }
    );
    fetch(request)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Something went wrong on api server!');
        }
      })
      .then(response => {
        if (response.hasOwnProperty('error')) {
          this.clearItenerary();
        }
        else if (response.hasOwnProperty('plan')) {
          if (response.plan.itineraries.length > 0 &&
              response.plan.itineraries[0].legs.length > 0 &&
              response.plan.itineraries[0].legs[0].steps.length > 0) {

            this.setState({
              duration:   response.plan.itineraries[0].duration,
              distance:   response.plan.itineraries[0].legs[0].distance,
              directions: response.plan.itineraries[0].legs[0].steps
            },
            () => {
              // convert the encoded coordinates back to an array of coordinates
              let latlngs = polyUtil.decode(response.plan.itineraries[0].legs[0].legGeometry.points);
              // update the map with the polylines
              this.props.map.setPolylineOverlay(latlngs);
            });

          } else {
            this.clearItenerary();
          }
          // to show the overlay after adding it to the page use: (pass in the specific overlay to show)
          // this.map.showItems(this.map.annotations, {animate: false});
        } else {
          this.clearItenerary();
        }
      }).catch(error => {
        console.error(error);
        this.clearItenerary();
      });
  }

  clearItenerary() {
    if (this.state.duration > 0) {
      this.setState({
        duration:   0,
        distance:   0,
        directions: []
      },
      () => {
        // update the map with the polylines
        this.props.map.setPolylineOverlay([])
      });
    }
  }
}

export default Directions;
