const VALID_FILTERS = [
  'ACADEMIC_ADMINISTRATIVE',
  'ADA_ENTRANCES',
  'ADA_ROUTES',
  'ATHLETICS',
  "BUS",
  'DINING',
  'DUKE_CARD',
  'EMERGENCY_PHONE',
  'LACTATION_ROOM',
  'MEDICAL_CENTER',
  'VISITOR_PARKING',
  'PERMIT_PARKING',
  'MOTORCYCLES_PARKING',
  'BICYCLES_PARKING',
  'HOUSING',
  'DUKE_STORES',
  'UNDER_CONSTRUCTION',
  'PERFORMANCE_VENUES',
]


export default class DeepLink {
  routeLink(updateAppState) {

    let _,
      params = new URLSearchParams(window.location.search);
    const showParam = params.get("show")
    if (showParam) {
      const filters = showParam.split(',')
      const arr = []
      filters.forEach(filter => {
        if (VALID_FILTERS.includes(filter.toUpperCase())) {
          arr.push(filter.toUpperCase())
        }
      });

      if (arr.length > 0) {
        updateAppState('deep_link_filters', arr);
      }
    }

  }
}
