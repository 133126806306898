import React, { Component } from 'react';
import './LocationExpandableDining.css';

export default class LocationExpandableDining extends Component {
  constructor(props) {
    super(props);

    this.updateOverlay = this.updateOverlay.bind(this);
  }

  render() {
    if (this.props.data !== undefined) {
      let restaurants = [];
      this.props.data.map((currentValue) => {
        restaurants.push(
          <li key={ currentValue.id }>
            <a
              href="/#"
              data-id={ currentValue.id }
              onClick={ (e) => this.updateOverlay(e) }>
              { currentValue.name }
              <span className={ currentValue.openNow ? 'dining-open' : 'dining-closed' }>
                { currentValue.openNow ? 'Open' : 'Closed' }
              </span>
            </a>
          </li>
        );

        return null;
      });

      return (
        <div id="dining" className={ this.props.expanded ? 'expanded' : 'collapsed' }>
          <div className="container">
            <ul className="list-decor-none">
              { restaurants }
            </ul>
          </div>
        </div>
      );
    }

    return null;
  }

  /**
   * Show the restaurant that was selected for focus from the building overlay.
   */
  updateOverlay(e) {
    e.preventDefault();
    this.props.updateFocus(e.target.getAttribute('data-id'));

    return false;
  }
}
