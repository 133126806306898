import React, { Component } from 'react';
import Overlay              from '../Overlay/Overlay';
import './Map.css';
import AMap                 from './MapHelperClass.js';
import PerformSearch        from '../models/graphql.js';
import BusSubscriptions     from './TranslocVehicles';
import DeepLink from '../DeepLink';
/**
 * Properties list:
 * - updateOverlays
 *   Callback function to update the results state in App.js.
 * - focus
 *   A string representing the unique id of the location to focus on.
 *
 */
export default class Map extends Component {
  constructor(props) {
    super(props);

    this.get_jwt_token                      = this.get_jwt_token.bind(this);
    this.collectAllLocations                = this.collectAllLocations.bind(this);
    this.setActiveRoutes                    = this.setActiveRoutes.bind(this);
    this.buildGeoJson                       = this.buildGeoJson.bind(this);
    this.clearSelectedOverlays              = this.clearSelectedOverlays.bind(this);
    this.collectFilterMarkers               = this.collectFilterMarkers.bind(this);
    this.setSpecificBuildingOverlayIfExists = this.setSpecificBuildingOverlayIfExists.bind(this);
    this.annotationHoverAction              = this.annotationHoverAction.bind(this);
    this.deepLink                           = this.deepLink.bind(this);
  }

  componentDidMount() {
    // load the apple mapkit
    this.map = new AMap(this.get_jwt_token, this.deepLink);
    // render the initial map
    this.map.renderMap();
    // collect a list of overlays
    this.collectAllLocations();
    // check to see if we have any default routes to show
    if (this.props.routeCollection.length > 0) {
      this.setActiveRoutes();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if no focus make sure all overlays and annotations are deselected
    if (this.props.focus === null) {
      if (this.map.map.selectedAnnotation !== null) {
        this.map.map.selectedAnnotation.selected = false;
      }
      else if (this.map.map.selectedOverlay !== null) {
        this.clearSelectedOverlays();
      }
    }

    // check to see if the markers to be shown have changed
    if (prevProps.markerCollection !== this.props.markerCollection) {
      if (window.location.search.length > 0 && window.location.search.slice(1).split("&")[1] !== undefined) {
        // this captures the one scenario when we want the overlay to maintain
        // focus when selecting to show new filter options for the map (bus routes)
      }
      else {
        // clear old annotations
        this.map.map.removeAnnotations(this.map.map.annotations);
      }

      // this.clearSelectedOverlays();
      // update the marker collections on the map
      this.map.setMarkerCollection(this.props.markerCollection, this.props.updateFocus);

    } else if (
        this.props.markerCollection.length > 0 &&
        typeof this.props.focus !== 'undefined' &&
        this.props.focus === ''
      ) {
      // this.map.map.removeAnnotations(this.map.map.annotations);
      this.map.setMarkerCollection(this.props.markerCollection, this.props.updateFocus);
    }

    // check to see if the filters have changed
    if (prevProps.filters !== this.props.filters) {
      // loop through each filter type
      for(let filter in this.props.filters) {
        // check to see if this specific filter changed
        if (prevProps.filters[filter] !== this.props.filters[filter]) {
          this.map.map.filters = this.props.filters;
          // check to see if we are adding or removing the filter markers
          if (this.props.filters[filter]) {
            if (this.map.updateOverlayColor(filter)) {
              // add the new filter markers
              this.collectFilterMarkers(filter);
            }
          }
          else {
            if (this.map.updateOverlayColor(filter, true)) {
              // remove the old filter markers
              this.collectFilterMarkers(filter, prevProps);
            }
          }
        }
      }
      this.props.setFilterPath(this.props.filters);
    }

    // check to see if active routes should be shown or removed
    if (prevProps.routeCollection !== this.props.routeCollection) {
      this.setActiveRoutes();
    }
  }

  render() {
    return (
      <div id="map-container">
        <BusSubscriptions
          map={ this.map === undefined ? null : this.map }
          busData={ this.props.markerCollection.TRANSLOC_BUSES }
          routes={ this.props.markerCollection.TRANSLOC_STOPS } />
        <Overlay
          apollo={ this.props.apollo }
          map={ this.map === undefined ? null : this.map }
          focus={ this.props.focus }
          searchResults={ this.props.searchResults }
          annotationHoverAction={ this.annotationHoverAction }
          clearSelectedOverlays={ this.clearSelectedOverlays }
          setSpecificBuildingOverlayIfExists={ this.setSpecificBuildingOverlayIfExists }
          routeCollection={ this.props.routeCollection }
          updateAppState={ this.props.updateAppState }
          isMobile={ this.props.isMobile }
          updateFocus={ this.props.updateFocus } />
        <div id="map"></div>
      </div>
    );
  }

  /**
   * Collect a fresh JWT token.
   */
  get_jwt_token() {
    return this.props.apollo.query({
      query: PerformSearch.get_jwt_token,
      fetchPolicy: "no-cache"
    });
  }

  deepLink() {
    (new DeepLink()).routeLink(this.props.updateAppState);
  }

  collectAllLocations() {
    this.props.apollo.query({
      query: PerformSearch.get_locations
    }).then(result => {
      this.props.updateOverlays(result.data.search.locations.records);
      this.map.addBuildingOverlays(this.buildGeoJson(), this.props.updateFocus);
    });
  }

  /**
   * Collect data for a list of active routes to be shown on the map.
   */
  setActiveRoutes() {
    if (this.props.routeCollection.length > 0) {
      this.props.apollo.query({
        query: PerformSearch.get_translocRoute,
        variables: {
          route: this.props.routeCollection.toString()
        }
      }).then(result => {
        // add the new markers to the marker collection
        this.props.showMarkerCollection('TRANSLOC_STOPS', result.data.translocRoutes.data);
      });
    }
    else {
      // clear the old markers
      this.props.showMarkerCollection('TRANSLOC_STOPS', []);
    }
  }

  annotationHoverAction(id) {
    this.map.map.annotations.forEach((annotation, index) => {
      if (annotation.data.hasOwnProperty('id')) {
        if (annotation.data.id === id) {
          if (annotation.titleVisibility === 'hidden') {
            annotation.titleVisibility = 'visible';
          } else {
            annotation.titleVisibility = 'hidden';
          }
        }
      }
    });
  }

  /**
   * Collects the filter icons to be shown on the map.
   */
  collectFilterMarkers(filter, prevProps) {
    // ADA ROUTES is a $byType query instead of a $byCategory query
    let query = PerformSearch.filters,
        vars  = { byCategory: filter };
    if (filter === 'ADA_ROUTES') {
      query = PerformSearch.filters_byType;
      vars  = { byType: filter };
    }

    if (prevProps === undefined && filter) {
      this.props.apollo.query({
        query: query,
        variables: vars
      }).then(result => {
        this.props.showMarkerCollection(filter, result.data.search.locations.records);
      });
    }
    else {
      // remove the existing markers for this filter type
      this.props.showMarkerCollection(filter, []);
    }
  }

  /**
   * Checks to see if the locationId has an overlay on the map.
   *
   * If so - it will set that overlay as the active overlay.
   * If not - it will set an annotation for the coordinates for that locationId.
   *
   * @param {string} locationId
   *   The unique identifier for a specific location.
   */
  setSpecificBuildingOverlayIfExists(locationId, data) {
    let overlayAndAnnotationNotFound = true;
    let coordinates;

    // first we look through the current overlay list to see if
    // the overlay exists on the map
    for (let overlay in this.map.map.overlays) {
      if (this.props.focus === this.map.map.overlays[overlay].data.id) {
        // set the location as selected
        this.map.map.overlays[overlay].selected = true;
        // we did find an overlay that matches our search
        overlayAndAnnotationNotFound = false;
        break;
      }
    }

    // second we look through the existing annotations to see
    // if the marker is already on the page
    if (overlayAndAnnotationNotFound) {
      for (let annotation in this.map.map.annotations) {
        if (this.props.focus === this.map.map.annotations[annotation].data.id) {
          // zoom in on the coordinates
          coordinates = data.coordinates.split(',');
          this.map.setFocusArea(Number(coordinates[0]), Number(coordinates[1]));
          // set the existing annotation as selected
          this.map.map.annotations[annotation].selected = true;
          overlayAndAnnotationNotFound = false;
          break;
        }
      }
    }

    // no existing overlay, just add a marker for the coordinates provided
    if (overlayAndAnnotationNotFound && data.type !== 'Buildings') {
      // zoom in to the new marker
      coordinates = data.coordinates.split(',');
      this.map.setFocusArea(Number(coordinates[0]), Number(coordinates[1]));
      // a focus change was requested, and the marker did not exist
      this.map.setFocusAreaMarker(
        data.nameDisplay,
        Number(coordinates[0]),
        Number(coordinates[1]),
        data.type,
        data.category,
        data.id,
        this.props.updateFocus,
        data.attrOpenNow,
        true
      );
    }
  }

  /**
   * Clears any overlays that are currently selected.
   */
  clearSelectedOverlays() {
    if (this.map.map.selectedOverlay !== null) {
      this.map.map.selectedOverlay.selected = false;
    }
  }

  buildGeoJson() {
    if (this.props.overlays) {
      let Buildings = {
        "type": "FeatureCollection",
        "name": "Duke_Buildings_OIT",
        "crs": {
          "type": "name",
          "properties": {
            "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
          }
        },
        "features": []
      };

      if (Array.isArray(this.props.overlays)) {
        this.props.overlays.forEach((obj, index) => {
          if (obj !== undefined) {
            obj.geojson.properties.attrAddress = obj.attrAddress;
            obj.geojson.properties.coordinates = obj.coordinates;
            obj.geojson.properties.category    = obj.category;
            obj.geojson.properties.focused = this.props.focus === obj.id ? true : false;
            // update the object properties
            Buildings.features.push(obj.geojson);
          }
        });
      }

      return Buildings;
    }
  }
}
