import gql from "graphql-tag";

/**
 * Get accessor for our GraphQL queries.
 */
class CreateSubscription {

  /**
   * Collects subscribes to bus data.
   */
  static get subscribe_translocVehicles() {
    const SUBSCRIBE_TRANSLOCVEHICLES = gql`
      subscription translocVehicles($routes: String!) {
        translocVehicles(routes: $routes){
          data{
            callName
            routeColor
            heading
            location{
              lat
              lng
            }
            speed
            trackingStatus
          }
        }
      }`;

    return SUBSCRIBE_TRANSLOCVEHICLES;
  }

  static get subscribe_translocArrivalEstimates() {
    const SUBSCRIBE_TRANSLOCARRIVALESTIMATES = gql`
      subscription translocArrivalEstimates($stops: String!) {
        translocArrivalEstimates(stops: $stops) {
          data{
            agencyId
            arrivals {
              arrivalAt
              routeId
              routeName
              type
              vehicleId
              routeColor
            }
            stopId
            stopName
          }
        }
      }`;

    return SUBSCRIBE_TRANSLOCARRIVALESTIMATES;
  }
}

export default CreateSubscription;
