import React, { Component } from 'react';
import './LocationExpandableHours.css';

export default class LocationExpandableHours extends Component {
  render() {
    if (this.props.hours) {
      let schedule = [];
      for(let dailySchedule in this.props.hours) {
        // collect the hours for a specific day
        let hours = [];
        if (this.props.hours[dailySchedule].hours.length > 0) {
          for(let hour in this.props.hours[dailySchedule].hours) {
            hours.push(this.props.hours[dailySchedule].hours[hour].rangeFormat);
          }
        }
        // add the hours and day to a schedule variable
        schedule.push(
          <li key={ this.props.hours[dailySchedule].dateFormat }>
            { this.props.hours[dailySchedule].dateFormat }
            <span className="pull-right">{ hours }</span>
          </li>
        );
      }

      return (
        <div id="hours" className={ this.props.expanded ? 'expanded' : 'collapsed' }>
          <div className="container">
            <ul className="list-decor-none">
              { schedule }
            </ul>
          </div>
        </div>
      );
    }

    return null;
  }
}
