import React, { Component } from 'react';
// import './Print.css';

class Print extends Component {

  render() {
    if (!this.props.isMobile) {
      return (
        <a href="#/" onClick={() => window.print() }>
          <i className="fas fa-print"></i> PRINT
        </a>
      );
    }

    return null;
  }
}

export default Print;
