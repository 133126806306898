import React, { Component } from 'react';
import './LocationOpenStatus.css';

export default class LocationOpenStatus extends Component {
  render() {
    if (typeof this.props.openStatus !== 'undefined' && this.props.openStatus !== null) {
      let status = this.props.openStatus ? 'Open' : 'Closed';
      let styleName = this.props.openStatus ? 'open' : 'closed';
      
      return (
        <p id="location-openstatus">
          <span className={ styleName }>{ status } </span>
          { this.props.openStatusMessage }
        </p>
      );
    }
    
    return null;
  }
}
