/**
 * Helper Class for handling hash redirects.
 */

const HASH_MAP = {
  '#!m/270158': '210',
  '#!m/4998':   '360',
  '#!m/4691':   '361',
  '#!m/2824':   '362',
  '#!m/3835':   '358',
  '#!m/513348': '123',
  '#!m/2738':   '122',
  '#!m/2714':   '121',
  '#!m/3894':   '147',
  '#!m/2761':   '215',
  '#!m/49902':  '176',
  '#!m/2692':   '216',
  '#!m/4689':   '274',
  '#!m/2699':   '245',
  '#!m/4692':   '244',
  '#!m/2701':   '243',
  '#!m/2700':   '140',
  '#!m/195252': '1873',
  '#!m/49898':  '172',
  '#!m/3909':   '275',
  '#!m/3896':   '47',
  '#!m/2776':   '70',
  '#!m/2769':   '38',
  '#!m/49004':  '171',
  '#!m/2867':   '323',
  '#!m/2750':   '136',
  '#!m/9994':   '239',
  '#!m/49017':  '5',
  '#!m/2745':   '166',
  '#!m/2869':   '291',
  '#!m/2751':   '169',
  '#!m/2702':   '42',
  '#!m/3826':   '205',
  '#!m/3823':   '45',
  '#!m/228818': '349',
  '#!m/4980':   '399',
  '#!m/3824':   '135',
  '#!m/4681':   '1882',
  '#!m/7412':   '138',
  '#!m/2856':   '177',
  '#!m/40672':  '307',
  '#!m/49005':  '200',
  '#!m/2773':   '68',
  '#!m/49899':  '96',
  '#!m/6149':   '1884',
  '#!m/3892':   '317',
  '#!m/5701':   '286',
  '#!m/2721':   '63',
  '#!m/2861':   '178',
  '#!m/2747':   '198',
  '#!m/5723':   '279',
  '#!m/9428':   '129',
  '#!m/40673':  '290',
  '#!m/6830':   '53',
  '#!m/2690':   '196',
  '#!m/3878':   '148',
  '#!m/2752':   '160',
  '#!m/2777':   '51',
  '#!m/2857':   '182',
  '#!m/49019':  '18',
  '#!m/49026':  '31',
  '#!m/49128':  '30',
  '#!m/49028':  '29',
  '#!m/49029':  '21',
  '#!m/49030':  '19',
  '#!m/49020':  '28',
  '#!m/49023':  '27',
  '#!m/49024':  '26',
  '#!m/49025':  '25',
  '#!m/49033':  '24',
  '#!m/49032':  '20',
  '#!m/49034':  '23',
  '#!m/48941':  '1869',
  '#!m/2757':   '289',
  '#!m/49037':  '10',
  '#!m/49038':  '6',
  '#!m/49039':  '70',
  '#!m/49040':  '11',
  '#!m/49035':  '8',
  '#!m/49036':  '9',
  '#!m/5756':   '1849',
  '#!m/4260':   '1871',
  '#!m/2770':   '335',
  '#!m/2771':   '336',
  '#!m/6566':   '334',
  '#!m/5705':   '211',
  '#!m/3916':   '110',
  '#!m/3869':   '295',
  '#!m/3867':   '296',
  '#!m/3865':   '297',
  '#!m/3870':   '298',
  '#!m/3898':   '294',
  '#!m/18737':  '299',
  '#!m/3868':   '283',
  '#!m/3871':   '302',
  '#!m/3901':   '284',
  '#!m/3900':   '52',
  '#!m/3917':   '345',
  '#!m/4939':   '102',
  '#!m/5702':   '285',
  '#!m/13793':  '322',
  '#!m/49015':  '153',
  '#!m/49016':  '139',
  '#!m/26153':  '157',
  '#!m/91117':  '318',
  '#!m/2696':   '201',
  '#!m/49041':  '188',
  '#!m/49042':  '189',
  '#!m/49043':  '187',
  '#!m/49044':  '190',
  '#!m/49046':  '193',
  '#!m/4904':   '192',
  '#!m/39413':  '50',
  '#!m/2733':   '54',
  '#!m/49012':  '288',
  '#!m/2717':   '197',
  '#!m/2719':   '256',
  '#!m/49048':  '327',
  '#!m/49050':  '326',
  '#!m/49049':  '32',
  '#!m/2711':   '98',
  '#!m/2694':   '37',
  '#!m/76675':  '124',
  '#!m/104094': '293',
  '#!m/2732':   '33',
  '#!m/2756':   '159',
  '#!m/2703':   '55',
  '#!m/4673':   '1855',
  '#!m/2876':   '249',
  '#!m/2720':   '66',
  '#!m/2808':   '312',
  '#!m/55160':  '339',
  '#!m/6866':   '203',
  '#!m/2718':   '64',
  '#!m/3872':   '194',
  '#!m/2780':   '109',
  '#!m/441911': '365',
  '#!m/3895':   '341',
  '#!m/2728':   '101',
  '#!m/2863':   '116',
  '#!m/9429':   '355',
  '#!m/2778':   '149',
  '#!m/441516': '395',
  '#!m/2708':   '272',
  '#!m/228821': '344',
  '#!m/49054':  '282',
  '#!m/48945':  '184',
  '#!m/49055':  '13',
  '#!m/49060':  '324',
  '#!m/49059':  '17',
  '#!m/49058':  '16',
  '#!m/49061':  '14',
  '#!m/49057':  '15',
  '#!m/49062':  '124',
  '#!m/4684':   '1853',
  '#!m/3827':   '108',
  '#!m/3838':   '114',
  '#!m/2858':   '1',
  '#!m/2772':   '337',
  '#!m/3908':   '273',
  '#!m/6561':   '95',
  '#!m/2698':   '170',
  '#!m/33057':  '340',
  '#!m/3874':   '65',
  '#!m/6562':   '49',
  '#!m/176989': '379',
  '#!m/3873':   '69',
  '#!m/2870':   '208',
  '#!m/2726':   '97',
  '#!m/3913':   '338',
  '#!m/2765':   '206',
  '#!m/2766':   '99',
  '#!m/2872':   '36',
  '#!m/39569':  '376',
  '#!m/5709':   '306',
  '#!m/49006':  '46',
  '#!m/236261': '331',
  '#!m/3912':   '174',
  '#!m/5698':   '107',
  '#!m/2730':   '94',
  '#!m/104095': '204',
  '#!m/3875':   '150',
  '#!m/444223': '394',
  '#!m/441527': '377',
  '#!m/3877':   '59',
  '#!m/2723':   '60',
  '#!m/2724':   '61',
  '#!m/2722':   '58',
  '#!m/2755':   '158',
  '#!m/268226': '351',
  '#!m/2737':   '117',
  '#!m/2710':   '202',
  '#!m/3836':   '183',
  '#!m/228822': '347',
  '#!m/2774':   '67',
  '#!m/2864':   '173',
  '#!m/49149':  '260',
  '#!m/2871':   '164',
  '#!m/2768':   '39',
  '#!m/2767':   '207',
  '#!m/228820': '356',
  '#!m/49013':  '132',
  '#!m/3910':   '175',
  '#!m/190714': '348',
  '#!m/2731':   '62',
  '#!m/2734':   '57',
  '#!m/2729':   '92',
  '#!m/2873':   '162',
  '#!m/48996':  '125',
  '#!m/3834':   '151',
  '#!m/398024': '352',
  '#!m/46984':  '93',
  '#!m/3920':   '255',
  '#!m/6563':   '56',
  '#!m/3911':   '34',
  '#!m/49063':  '72',
  '#!m/49064':  '75',
  '#!m/49065':  '74',
  '#!m/49066':  '73',
  '#!m/40715':  '319',
  '#!m/41830':  '4',
  '#!m/41831':  '373',
  '#!m/91118':  '71',
  '#!m/2753':   '156',
  '#!m/49010':  '303',
  '#!m/6829':   '179',
  '#!m/3837':   '181',
};

export default class Redirects {
  routeRedirects() {
    let newUrl,
        params = new URLSearchParams(window.location.search);

    // check for the presence of the id and a hash
    if (params.has('id') && window.location.hash) {
      if (HASH_MAP.hasOwnProperty(window.location.hash)) {
        newUrl = window.location.pathname + '?focus=' + HASH_MAP[window.location.hash];
        window.location.replace(newUrl);
      }
    }
  }
}
