import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import styles from './DirectionsSearchField.module.css';
import './DirectionsSearchField.css';
import PerformSearch from '../../../models/graphql.js';

class DirectionsSearchField extends Component {
  constructor(props) {
    super(props);

    // set the default properties of the application state
    this.state = {
      isLoading: false,
      value:     this.props.value,
      options:   []
    };

    this.inputChange  = this.inputChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      if (!this.props.disabled) {
        this.setState({
          value: {value: this.props.value, label: this.props.value}
        });
      }
      else {
        // an area was focused, lets clear the type ahead options
        this.setState({ value: this.props.value });
      }
    }
  }

  render() {
    if (!this.props.disabled) {
      return (
        <CreatableSelect
          isClearable
          placeholder="Search..."
          className={ styles.creatable }
          classNamePrefix="duke"
          onCreateOption={ this.handleCreate }
          formatCreateLabel={ this.formatCreateLabelCreator }
          onInputChange={ this.inputChange }
          onChange={ this.handleChange }
          value={ this.state.value }
          isLoading={ this.state.isLoading }
          options={ this.state.options } />
      );
    }

    return (
      <>
        <input
          tabIndex={ this.props.tabIndex }
          type="search"
          name="directions"
          autoComplete="off"
          disabled={ this.props.disabled }
          value={ this.state.value }
          aria-label="Starting address"
          aria-owns="search-results"
          placeholder="Address search"
          onKeyUp={ this.keyPress }
          onChange={ this.handleChange } />
      </>
    );
  }

  /**
   * Format the search label.
   */
  formatCreateLabelCreator(value) {
    return 'Search: ' + value;
  }

  /**
   * User is searching using an address.
   */
  handleCreate(value) {
    const createOption = (label) => ({
      label,
      value: label.toLowerCase(),
    });

    // set the state to loading
    this.setState({ isLoading: true },
      () => {
        const { options } = this.state;
        const newOption = createOption(value);
        this.setState({
          isLoading: false,
          options: [...options, newOption],
          value: newOption,
        },
        () => {
          this.props.onChange(newOption.value, newOption.label);
        });
    });
  }

  /**
   * User updated the address lookup search field.
   */
  handleChange(newValue, actionMeta) {
    this.setState({ value: newValue },
    () => {
      this.props.onChange(this.props.elId, newValue);
    });
  }

  /**
   * User is searching for either a duke building or an address.
   */
  inputChange(value, actionMeta) {
    this.props.apollo.query({
      query: PerformSearch.string_search,
      variables: {
        search: value,
        rows: 100
      }
    }).then(result => {
      let data = [];
      if (result.data.search.locations.records.length > 0) {
        data = result.data.search.locations.records.map((item, index) => {
          return { value: item.id, label: item.nameDisplay, latLng: {latitude: item.lat, longitude: item.long}};
        });
      }

      this.setState({ options: data });
    });
  }
}

export default DirectionsSearchField;