import React, { Component } from 'react';
import './MobileAppCTA.css';

export default class MobileAppCTA extends Component {
  constructor(props) {
    super(props);

    // set the default properties of the application state
    this.state = { deviceType: this.getDeviceType() };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isMobile !== this.props.isMobile) {
      // collect the device type
      let deviceType = this.getDeviceType();
      if (prevState.deviceType !== deviceType) {
        // udpate the components deviceType state
        this.setState({ deviceType: deviceType });
      }
    }
  }

  render() {
    let appLink = null;
    // check to see if the user has the notification cookie
    if (this.props.showMobileCTA) {
      // check the device type for this user
      if (this.state.deviceType !== 'desktop') {
        // build the link specific for this device type
        if (this.state.deviceType === 'apple') {
          appLink = (
            <a href="https://apps.apple.com/us/app/dukemobile/id306796270?ls=1">
              Get the app now.
            </a>
          );
        }
        else if (this.state.deviceType === 'andriod') {
          appLink = (
            <a href="https://play.google.com/store/apps/details?id=com.blackboard.android.central.duke&hl=en_US">
              Get the app now.
            </a>
          );
        }
      }

      return (
        <div id="mobile-app-cta">
          <span>For the best map experience, download the Duke Mobile app. </span>
          { appLink }
          <button
            onClick={ () => { this.props.updateAppState('mobileCTA', false) } }>
            GOT IT
          </button>
        </div>
      );
    }

    return null;
  }

  /**
   * Helper function to determine device type.
   *
   * @return string
   *   Returns the device type brand. apple or andriod.
   *   Returns desktop by default.
   */
  getDeviceType() {
    let iDevices = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ];
    let aDevices = [
      'Android'
    ];

    if (!!navigator.userAgent) {
      // check for apple devices
      while (iDevices.length) {
        if (navigator.userAgent.search(iDevices.pop()) !== -1) { return 'apple'; }
      }
      // check for andriod devices
      while (aDevices.length) {
        if (navigator.userAgent.search(aDevices.pop()) !== -1) { return 'andriod'; }
      }
    }

    return 'desktop';
  }
}
