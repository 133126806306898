import React, { Component } from 'react';
// import './TranslocVehicles.css';
import NewSubscription      from '../models/subscriptions.js';
import { Subscription }     from 'react-apollo';
// import moment               from 'moment-timezone';

class TranslocVehicles extends Component {
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    // clear old bus icons from the component prior to rendering new
    this.props.map.updateBusSubscriptionMarkers([]);
  }

  render() {
    // collect the route ids to get bus data for
    let busRoutes = this.props.routes.map((route, index) => {
      return route.routeId;
    }).toString();
    if (this.props.routes.length === 0) {
      return null;
    }

    return (
      <Subscription
        subscription={ NewSubscription.subscribe_translocVehicles }
        variables={{ routes: busRoutes }}>
        {({ data, loading }) => {
          if (!loading) {
            if (data.translocVehicles.data.length > 0) {
              // comparing data here to prevent a large number of global stateresets
              if (this.props.busData !== data.translocVehicles.data) {
                this.props.map.updateBusSubscriptionMarkers(data.translocVehicles.data);
              }
            }
          }

          return null;
        }}
      </Subscription>
    );
  }
}

export default TranslocVehicles;
