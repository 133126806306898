import React, { Component } from 'react';
import PerformSearch        from '../../models/graphql.js';
import CategoryResults      from '../../Overlay/Article/SearchResults/SearchResults';

class CategoryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: []
    }
  }

  componentDidMount() {
    if (this.props.hasOwnProperty('category')) {
      this.props.apollo.query({
        query: PerformSearch.filters,
        variables: { byCategory: this.props.category }
      }).then(result => {
        if (result.data && result.data.search.locations.records.length > 0) {
          this.setState({
            list: result.data.search.locations.records
          });
        }
      });
    }
  }

  render() {
    if (this.state.list.length > 0) {
      return (
        <article id="location-details">
          <CategoryResults
            updateFocus={ this.props.updateFocus }
            properties={ {results: this.state.list} }/>
        </article>
      );
    }

    return (
      <article id="location-details">
        <div className="container">
          <p>Loading category results...</p>
        </div>
      </article>
    );
  }
}

export default CategoryList;
