import React, { Component } from 'react';
import './DirectionsResults.css';
import DirectionsStep from './DirectionsStep';

class DirectionsResults extends Component {
  render() {
    if (this.props.directions.constructor === Array && this.props.directions.length > 0) {
    	let duration 						= 0,
    			distance						= 0,
    			distanceText				= 'ft',
    			directions 					= [],
    			directionsReversed	= this.props.directions.reverse();

    	// figure out the distance
    	if (this.props.duration > 0) {
    		duration = (this.props.duration / 60).toFixed(0);
    	}
    	// figure out the number of miles
    	if (this.props.distance > 0) {
    		// convert the distance to ft
    		distance = (this.props.distance/0.3048).toFixed(2);
    		// if grater than .10 miles use miles instead of ft
    		if (distance > 528) {
    			// convert the distance to miles
    			distance = (this.props.distance * 0.000621371192).toFixed(2);
    			distanceText = 'miles';
    		}
    	}
    	// build the direction step component
    	for (var i = directionsReversed.length - 1; i >= 0; i--) {
    		directions.push(
    			<DirectionsStep
    				key={ i }
    				type={ this.props.type }
    				leg={ directionsReversed[i] } />
    		);
    	}

      // add the final destination if this is a duke known building
      let finalDestination = null;
      if (this.props.active_field === 'start') {
        finalDestination = (
          <div>
            <span className="final-destination">{ this.props.name }</span>
            <address>{ this.props.address }</address>
          </div>
        );
      }

      return (
    		<div>
		      <h2>{ duration } min ({ distance } { distanceText })</h2>
		      { directions }
          { finalDestination }
		    </div>
  		);
    }

    return (
      <div>
	      <p>Search using a valid address to get an itenerary.</p>
	    </div>
    );
  }
}

export default DirectionsResults;
