import React, { Component } from 'react';
import './LocationImages.css';

export default class LocationImages extends Component {

  render() {
    if (this.props.images) {
      let images = [];
      for(let key in this.props.images) {
        if (images.length < 3) {
          images.push(
            <img
              key={ key }
              src={ this.props.images[key].url }
              alt={ this.props.images[key].title } />
          );
        }
      }

      return images;
    }

    return null;
  }
}
