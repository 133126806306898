import React, { Component } from 'react';
import './DirectionsStep.css';

class DirectionsStep extends Component {
  render() {
    let joinString;
    joinString = (this.props.type === 'bicycle' || this.props.type === 'walk') ? ' on to ' : ' on ';
    let instructionString;
    if (this.props.leg.relativeDirection === 'DEPART') {
      instructionString = 'Start' + joinString + this.props.leg.streetName + ' heading ' + this.props.leg.absoluteDirection;
    } else {
      instructionString = this.props.leg.relativeDirection + joinString + this.props.leg.streetName;
    }

    // convert the distance to ft
    let distance = (this.props.leg.distance/0.3048).toFixed(2);
    let distanceText = 'ft';
    // if greater than .10 miles use miles instead of ft
    if (distance > 528) {
      // convert the distance to miles
      distance = (this.props.leg.distance * 0.000621371192).toFixed(2);
      distanceText = 'mi';
    }

    return (
  		<div className="leg">
        <div className="directional-arrow"></div>
	      <div className="direction-leg-details">
          <p>
            { instructionString }
          </p>
          <div className="footing">
            <span className="distance">{ distance } { distanceText }</span><hr align="center" />
          </div>
        </div>
	    </div>
		);
  }


}

export default DirectionsStep;
