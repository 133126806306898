import React, { Component } from 'react';
import './ShareButton.css';

class ShareButton extends Component {
  componentDidMount() {
    // load the apple mapkit
  }

  render() {
    return (
      <button 
      	id="shareButton"
      	name="share"
      	onClick={ this.props.updateType }>Share Location</button>
    );
  }
}

export default ShareButton;
