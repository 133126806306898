import React, { Component } from 'react';
import './LocationExpandable.css';
import Hours from './LocationExpandableHours/LocationExpandableHours';
import Description from './LocationExpandableDescription/LocationExpandableDescription';
import BuildingInfoIcon from '../../../img/overlay_icons/info_icon.png';
import DiningInfoIcon from '../../../img/overlay_icons/food_icon.png';
import Dining from './LocationExpandableDining/LocationExpandableDining';

export default class LocationExpandable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoursExpanded: false,
      descriptionExpanded: true,
      diningExpanded: false
    }
  }

  render() {
    // interactive overlay buttons
    let buttons = [];
    // build the toggle button
    let buildToggle = (setting) => {
      var faClass = 'fa fa-caret-right';
      if (this.state[setting]) {
        faClass = 'fa fa-caret-down';
      }

      return (
        <span className='arrow'>
          <i className={ faClass }></i>
        </span>
      );
    };

    // expandable buttons for places
    if (this.props.data.type === 'Places') {
      // make sure that we have a open/closed schedule for this location
      if (this.props.data.attrSchedule !== null) {
        buttons.push(
          <div key="1">
            <button
              className='h3'
              aria-expanded={ this.state.hoursExpanded }
              aria-controls="hours"
              onClick={ (e) => this.toggleVisibility('hoursExpanded') }>
              <span className="pull-left">Hours</span>
              <span className="pull-right carrot">{ buildToggle('hoursExpanded') }</span>
            </button>
            <Hours
              hours={ this.props.data.attrSchedule }
              expanded={ this.state.hoursExpanded} />
          </div>
        );
      }

      // make sure we have a menu url for this location
      if (this.props.data.attrMenuUrl !== null) {
        buttons.push(
          <button
            key="2"
            className='h3'
            onClick={ (e) => this.sendToMenuLink() }>
            <span className="pull-left">Menu</span>
            <span className="pull-right carrot">
              <span className='arrow'>
                <i className="fa fa-caret-right"></i>
              </span>
            </span>
          </button>
        );
      }

      if (buttons.length > 0) {
        return (
          <div id="location-expandable">
            <div>
              { buttons }
            </div>
          </div>
        );
      }

      return null;
    }

    // expandable buttons for buildings
    if (
      this.props.data.type === 'Buildings' ||
      this.props.data.type === 'Rooms' ||
      this.props.data.type === 'ADA Entrances' ||
      this.props.data.type === 'Point of Interest') {
      // only show the "building information" button if data exists
      if (this.props.data.description !== null || this.props.data.media !== null) {
        // build the interactive buttons
        buttons.push(
          <div key="1">
            <button
              className='h3'
              aria-expanded={ this.state.descriptionExpanded }
              aria-controls="description"
              onClick={ (e) => this.toggleVisibility('descriptionExpanded') }>
              <img className="pull-left" src={ BuildingInfoIcon } alt="building info icon" />
              <span className="pull-left">Information</span>
              <span className="pull-right carrot">{ buildToggle('descriptionExpanded') }</span>
            </button>
            <Description
              data={ this.props.data }
              expanded={ this.state.descriptionExpanded} />
          </div>
        );
      }

      // check to see if we need to show a dining interactive button
      if (this.props.data.relatedPlaces !== undefined) {
        this.props.data.relatedPlaces[0].formatNested.map( (currentValue, index) => {
          if (currentValue.category === 'Dining') {
            buttons.push(
              <div id="dining-btn" key="2">
                <button
                  className='h3'
                  aria-expanded={ this.state.diningExpanded }
                  aria-controls="dining"
                  onClick={ (e) => this.toggleVisibility('diningExpanded') }>
                  <img className="pull-left" src={ DiningInfoIcon } alt="dining info icon" />
                  <span className="pull-left">Dining</span>
                  <span className="pull-right carrot">{ buildToggle('diningExpanded') }</span>
                </button>
                <Dining
                  data={ currentValue.records }
                  updateFocus={ this.props.updateFocus }
                  expanded={ this.state.diningExpanded} />
              </div>
            );
          }

          return null;
        });
      }

      return (
        <div id="location-expandable" className="building">
          { buttons }
        </div>
      );
    }

    return null;
  }

  toggleVisibility(setting) {
    this.setState({ [setting]: !this.state[setting] });
  }

  sendToMenuLink() {
    window.location = this.props.data.attrMenuUrl;
  }
}
