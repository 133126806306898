import React, { Component } from 'react';
import './LocationPhone.css';

export default class LocationPhone extends Component {
  render() {
    if (this.props.phoneNumber) {
      return (
        <p id="location-phone">
          <i className="fas fa-phone"></i>
          <a href="tel:this.props.phoneNumber"> { this.props.phoneNumber }</a>
        </p>
      );
    }

    return null;
  }
}
