import React, { Component } from 'react';
import $ from 'jquery';
import './Share.css';

class Share extends Component {
  componentDidMount() {
  	$('#link-share').focus(function(){
  		this.select();
  	});
  	$('#embed-share').focus(function(){
  		this.select();
  	});
  }
  render() {
    // compose the iframe string
    let iframe = '<iframe ';
    		iframe += 'id="map_frame" ';
    		iframe += 'width="100%" ';
    		iframe += 'height="100%" ';
    		iframe += 'frameborder="0" ';
    		iframe += 'scrolling="no" ';
    		iframe += 'border="0" ';
    		iframe += 'allow="geolocation" ';
    		iframe += 'src="' + window.location.href + '">';
    		iframe += '</iframe>';

    return (
      <div id="sharing">
	      <label>
	      	<span>Link:</span>
	        <input
	        	id="link-share"
	        	readOnly={ true }
	        	autoComplete="off"
	        	type="text"
	        	name="link"
	        	value={ window.location.href }
	        	aria-label="Url to this page"
	        	onChange={this.handleChange} />
	      </label>
	      <label>
	      	<span>Embed:</span>
	        <input
	        	id="embed-share"
	        	readOnly={ true }
	        	autoComplete="off"
	        	type="text"
	        	name="end"
	        	value={ iframe }
	        	aria-label="iFrame embed code"
	        	onChange={this.handleChange} />
	      </label>
	    </div>
    );
  }
}

export default Share;
