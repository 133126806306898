import gql from "graphql-tag";

/**
 * Get accessor for our GraphQL queries.
 */
class PerformSearch {

  static get get_jwt_token() {
    const GET_JWT_TOKEN = gql`
      query AppleMap{
        appleMap{
          token
        }
      }`;

    return GET_JWT_TOKEN;
  }
  /**
   * Collects all locations for all Duke Buildings.
   */
  static get get_locations() {
    const GET_LOCATIONS = gql`
      query Search($rows: Int){
        search(rows: $rows){
          locations{
            records{
              id
              nameDisplay
              type
              category
              ... on Building{
                attrAddress
              }
              ... on Place{
                attrOpenNow
              }
              geojson{
                geometry {
                  coordinates
                  type
                }
                properties{
                  id
                  description
                  nameDisplay
                  lat
                  long
                  type
                  colorHex
                }
                type
              }
            }
          }
        }
      }`;

    return GET_LOCATIONS;
  }

  /**
   * Collect details for a specfic building.
   */
  static get get_location() {
    const GET_LOCATION = gql`
      query Search($byId: String){
        search(byId: $byId){
          locations{
            records{
              id
              nameDisplay
              type
              category
              description
              coordinates
              media{
                title
                url
              }
              ... on Parking{
                attrNote
                attrBusRoutes{
                  routeId
                  longName
                }
                attrValidPermit
                attrAfterHourAccess
                attrLotVisitor
                attrLotPermit
              }
              ... on Place{
                adaAccessible
                attrOpenNow
                attrOpenMessage
                attrAddress
                attrMenuUrl
                attrPhone
                description
                attrSchedule{
                  dateFormat
                  hours{
                    endTime
                    rangeFormat
                    startTime
                  }
                }
                media{
                  title
                  url
                }
              }
              ... on Building{
                attrAddress
                relatedPlaces{
                  formatFlat{
                    category
                  }
                  formatNested{
                    category
                    records{
                      id
                      name
                      openNow
                    }
                  }
                }
                geojson{
                  geometry {
                    coordinates
                    type
                  }
                  properties{
                    id
                    nameDisplay
                    lat
                    long
                    type
                    colorHex
                  }
                  type
                }
              }
            }
          }
        }
      }`;

    return GET_LOCATION;
  }

  /**
   * Perform a string search of possible locations and rooms.
   */
  static get string_search() {
    const STRING_SEARCH = gql`
      query Search($search: String, $rows: Int){
        search(search: $search, rows: $rows){
          locations{
            records{
              id
              nameDisplay
              type
              category
              lat
              long
              geojson{
                properties{
                  id
                  lat
                  long
                }
              }
              ... on Place{
                attrOpenNow
              }
            }
          }
        }
      }`;

    return STRING_SEARCH;
  }

  /**
   * Collect all of the possible filters by category.
   */
  static get filters() {
    const FILTERS = gql`
      query Search($byCategory: LocationCategories){
        search(byCategory: $byCategory){
          locations{
            records{
              id
              nameDisplay
              type
              category
              lat
              long
              geojson{
                geometry{
                  coordinates
                  type
                }
                properties{
                  id
                  lat
                  long
                }
              }
              ... on Place{
                attrOpenNow
              }
            }
          }
        }
      }`;

    return FILTERS;
  }

  /**
   * Collect all of the possible filters by category.
   */
  static get filters_byType() {
    const FILTERS = gql`
      query Search($byType: LocationTypes){
        search(byType: $byType){
          locations{
            records{
              id
              nameDisplay
              type
              category
              lat
              long
              geojson{
                geometry{
                  coordinates
                  type
                }
                properties{
                  id
                  lat
                  long
                }
              }
              ... on Place{
                attrOpenNow
              }
            }
          }
        }
      }`;

    return FILTERS;
  }

  /**
   * Collects all translocStops.
   */
  static get get_translocStops() {
    const GET_TRANSLOCSTOPS = gql`
      query translocStops {
        translocStops{
          data{
            code
            description
            location {
              lat
              lng
            }
            locationType
            name
            parentStationId
            stationId
            stopId
            url
          }
        }
      }`;

    return GET_TRANSLOCSTOPS;
  }

  /**
   * Collects all translocStops.
   */
  static get get_translocRoutes() {
    const GET_TRANSLOCROUTES = gql`
      query translocRoutes {
        translocRoutes{
          data{
            agencyId
            color
            description
            isActive
            isHidden
            longName
            routeId
            shortName
            textColor
            type
            url
            stopsData{
              code
              description
              location{
                lat
                lng
              }
              locationType
              name
              parentStationId
              stationId
              stopId
              url
            }
          }
        }
      }`;

    return GET_TRANSLOCROUTES;
  }

  /**
   * Collects all translocStops.
   */
  static get get_translocRoute() {
    const GET_TRANSLOCROUTE = gql`
      query translocRoutes($route: String){
        translocRoutes(route: $route){
          data{
            agencyId
            color
            isActive
            isHidden
            routeId
            textColor
            stopsData{
              code
              description
              location{
                lat
                lng
              }
              locationType
              name
              parentStationId
              stationId
              stopId
              url
            }
            segmentsData{
              key
              direction
              value
            }
          }
        }
      }`;

    return GET_TRANSLOCROUTE;
  }

  /**
   * Collects translocStops arrival times for a translocStop.
   */
  static get get_translocArrivalEstimates() {
    const GET_TRANSLOCARRIVALESTIMATES = gql`
      query translocArrivalEstimates($stops: String){
        translocArrivalEstimates(stops: $stops){
          data{
            agencyId
            arrivals{
              arrivalAt
              routeId
              routeName
              type
              vehicleId
            }
            stopId
            stopName
          }
        }
      }`;

    return GET_TRANSLOCARRIVALESTIMATES;
  }
}

export default PerformSearch;
