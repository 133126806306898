import polyUtil           from 'polyline-encoded';
import FoodMarker         from '../img/map_markers/food_icon2x.png';
import EmergencyMarker    from '../img/map_markers/emergency_icon2x.png';
import VenueMarker        from '../img/map_markers/venues_icon2x.png';
import LactationMarker    from '../img/map_markers/lactation_icon2x.png';
import DukeCardMarker     from '../img/map_markers/dukecard_icon2x.png';
import DukeStoresMarker   from '../img/map_markers/stores_icon2x.png';
import ResidentialMarker  from '../img/map_markers/residential_marker2x.png';
import MedicalMarker      from '../img/map_markers/medical_marker2x.png';
import AthleticMarker     from '../img/map_markers/athletic_marker2x.png';
import ConstructionMarker from '../img/map_markers/construction_marker2x.png';
import AdaEntranceMarker  from '../img/map_markers/ada-icon2x.png';

import ParkingVisitor     from '../img/map_markers/visitor_parking_icon2x.png';
import ParkingPermit      from '../img/map_markers/permit_parking_icon2x.png';
import ParkingMixed       from '../img/map_markers/mixed_parking_marker1-4x.png';
import ParkingMixedActive from '../img/map_markers/mixed_parking_marker2-2x.png';
import ParkingMotorcycle  from '../img/map_markers/motorcycle_parking_icon2x.png';
import ParkingBicycle     from '../img/map_markers/bicycle_parking_icon2x.png';

import BusStop            from '../img/map_markers/bus-stop.png';
import BusStopActive      from '../img/map_markers/bus-stop-active.png';
import BusIcon22          from '../img/map_markers/bus_icon-22.png';
import BusIcon67          from '../img/map_markers/bus_icon-67.png';
import BusIcon112         from '../img/map_markers/bus_icon-112.png';
import BusIcon157         from '../img/map_markers/bus_icon-157.png';
import BusIcon202         from '../img/map_markers/bus_icon-202.png';
import BusIcon247         from '../img/map_markers/bus_icon-247.png';
import BusIcon292         from '../img/map_markers/bus_icon-292.png';
import BusIcon337         from '../img/map_markers/bus_icon-337.png';
/**
 * Helper Class for AppleMap data manipulation.
 */
export default class AMap {
  constructor(get_token, deepLink) {
    // define data storage vars
    this.routePolylines   = [];
    this.busStops         = [];
    this.adaPolylines     = [];
    this.busSubscriptions = [];

    this.deepLink = deepLink
    this.mapkit   = window.mapkit;
    // initialize the map
    this.mapkit.init({
      authorizationCallback: function(done) {
        get_token()
          .then(value => value.data.appleMap.token)
          .then(done);
      },
      language: "en"
    });
  }

  /**
   * Renders an Apple map on screen.
   */
  renderMap() {
    var DukeCampus = new this.mapkit.CoordinateRegion(
      new this.mapkit.Coordinate(36.0, -78.939),
      new this.mapkit.CoordinateSpan(0.02, 0.02)
    );

    this.map = new this.mapkit.Map("map", {
      center: new this.mapkit.Coordinate(36.0, -78.939),
      region:                 DukeCampus,
      showsZoomControl:       true,
      showsCompass:           this.mapkit.FeatureVisibility.Visible,
      mapType:                this.mapkit.Map.MapTypes.Standard,
      showsMapTypeControl:    false,
      showsPointsOfInterest:  false,
      isRotationEnabled:      true
    });

    // needed to allow mouse scroll wheel to work
    this.map._allowWheelToZoom = true;

    // add keyboard navigation
    document.onkeydown = event => {
      const el = document.querySelector('body');
      if (el === document.activeElement) {
        switch(event.key) {
          case "ArrowDown":
            this.setFocusArea(this.map.center.latitude - .001, this.map.center.longitude);
            break;
          case "ArrowUp":
            this.setFocusArea(this.map.center.latitude + .001, this.map.center.longitude);
            break;
          case "ArrowLeft":
            this.setFocusArea(this.map.center.latitude, this.map.center.longitude - .001);
            break;
          case "ArrowRight":
            this.setFocusArea(this.map.center.latitude, this.map.center.longitude + .001);
            break;
          default:
        }
      }
    };
  }

  /**
   * Adds new colored overlays to the Apple map.
   *
   * @param {object} DukeBuildings
   * @param {ref} callback
   */
  addBuildingOverlays(DukeBuildings, callback) {
    // add overlays to the rendered map
    this.mapkit.importGeoJSON(
      DukeBuildings,
      this.geoJSONParserDelegate(this, callback)
    );
  }

  addAdaRouteData(routes, callback) {
    let data,
        style;

    // clear any old polyline data
    if (this.adaPolylines) {
      this.map.removeOverlays(this.adaPolylines);
      this.adaPolylines = [];
    }

    // loop through each selected route and process its data
    routes.forEach((route, index) => {
      data = JSON.parse(route.geojson.geometry.coordinates);
      // add the polylines to the map for this route
      if (data.length > 0) {
        // set the style for this polyline
        style = new this.mapkit.Style({
          lineWidth: 2,
          lineCap: "square",
          lineJoin: "round",
          lineDash: [8, 0],
          strokeColor: "#0577B1"
        });

        this.adaPolylines.push(
          new this.mapkit.PolylineOverlay(
            data.map((segment) => {
              return new this.mapkit.Coordinate(segment[1], segment[0]);
            }),
            { style: style }
          )
        );
      }
    });

    // add any polylines if they exist
    if (this.adaPolylines.length > 0) {
      this.map.addOverlays(this.adaPolylines);
    }
  }

  addTranslocRouteData(routes, callback) {
    let marker,
        style;

    // clear any old polyline data
    if (this.routePolylines) {
      this.map.removeOverlays(this.routePolylines);
      this.routePolylines = [];
    }
    // clear any old busStops data
    if (this.routePolylines) {
      this.map.removeAnnotations(this.busStops);
      this.busStops = [];
    }

    // loop through each selected route and process its data
    routes.forEach((route, index) => {
      // add the polylines to the map for this route
      if (route.segmentsData.length > 0) {
        // set the style for this polyline
        style = new this.mapkit.Style({
          lineWidth: 2,
          lineCap: "square",
          lineJoin: "round",
          lineDash: [8, 0],
          strokeColor: "#"+route.color
        });

        // loop through the array of segment point collections
        route.segmentsData.forEach((segment, index) => {
          // create the new polyline
          this.routePolylines.push(
            new this.mapkit.PolylineOverlay(
              polyUtil.decode(segment.value).map((point) => {
                return new this.mapkit.Coordinate(point[0], point[1]);
              }),
              { style: style }
            )
          );
        });
      }

      // add the stops data to the map
      if (route.stopsData.length > 0) {
        route.stopsData.forEach((stop) => {
          marker = new this.mapkit.ImageAnnotation(
            new this.mapkit.Coordinate(stop.location.lat, stop.location.lng),
            {
              // title: stop.name,
              url: { 1: BusStop, 2: BusStop},
              anchorOffset: new DOMPoint(0, -16),
              enabled: true,
              data: stop,
              accessibilityLabel: stop.name
            }
          );

          // add a click event handler
          marker.addEventListener("select", (e) => {
            // show the title
            e.target.titleVisibility = 'hidden';
            // enlarge the bus stop annotation
            e.target.url = { 1: BusStopActive, 2: BusStopActive };
            // set this area as in focus, showing overlay for the selected location
            callback(stop.locationType + '-'+ stop.stopId);
          });
          // add a click event handler
          marker.addEventListener("deselect", (e) => {
            // shrink the bus stop icon
            e.target.url = { 1: BusStop, 2: BusStop };
            // e.target.titleVisibility = 'hidden';
            // confirm that the user has deselected everything
            // if (this.map.selectedAnnotation === null && this.map.selectedOverlay === null) {
              callback('');
            // }
          });

          this.busStops.push(marker);
        });
      }
    });

    // add any polylines if they exist
    if (this.routePolylines.length > 0) {
      this.map.addOverlays(this.routePolylines);
    }
    // add any annotations if they exist
    if (this.busStops.length > 0) {
      this.map.addAnnotations(this.busStops);
    }
  }

  /**
   * Adds the translocStops (bus stops) to the map.
   *
   * @param array DukeBuildings
   * @param {ref} callback
   */
   addTranslocStopsImgAnnotations(stops, callback) {
    let marker,
        annotations = [];
    // loop through each bus stop and build the marker object
    stops.forEach((stop, index) => {
      marker = new this.mapkit.ImageAnnotation(
        new this.mapkit.Coordinate(stop.location.lat, stop.location.lng),
        {
          // title: stop.name,
          url: { 1: AthleticMarker, 2: AthleticMarker},
          anchorOffset: new DOMPoint(0, -16),
          enabled: true,
          data: stop,
          accessibilityLabel: stop.name
        }
      );

      // add a click event handler
      marker.addEventListener("select", (e) => {
        // show the title
        e.target.titleVisibility = 'hidden';
        // set this area as in focus, showing overlay for the selected location
        callback(stop.locationType + '-'+ stop.stopId);
      });
      // add a click event handler
      marker.addEventListener("deselect", (e) => {
        // remove any directional polylines if they exist
        // this.removePolylineOverlay();
        // hide the title
        // e.target.titleVisibility = 'hidden';
        // confirm that the user has deselected everything
        // if (this.map.selectedAnnotation === null && this.map.selectedOverlay === null) {
          callback('');
        // }
      });

      annotations.push(marker);
    });

    this.map.addAnnotations(annotations);
  }

  /**
   * Method over-ride to produce the building overlays.
   *
   * @see https://developer.apple.com/documentation/mapkitjs/mapkit/2974044-importgeojson
   *
   * @param  obj instance
   *   let instance = this from the class constructor.
   *
   * @return obj
   *   Returns an object of methods to produce the customized overlay effect.
   */
  geoJSONParserDelegate(instance, callback, source = 'buildings') {
    var mapState = this.map;
    // var overlayStorage = this.overlayStorage
    return {
      itemForFeature(item, geoJSON) {
        // define the initial building styles
        var bldgstyle = new instance.mapkit.Style({
          strokeColor: "#000",
          strokeOpacity: .5,
          lineWidth: .5,
          fillOpacity: 1,
          fillColor: '#f5f5dc'
          //fillColor: "rgba(" + geoJSON.properties.rgbaColor + ")",
        });

        /* TODO - loop all coordiante array components to capture inner & outer rings */
        let points;
        if (geoJSON.geometry !== null) {
          if (geoJSON.geometry.type === 'MultiPolygon') {
            points = geoJSON.geometry.coordinates[0][0].map(function(point) {
              return new instance.mapkit.Coordinate(point[1], point[0]);
            });
          } else if (geoJSON.geometry.type === 'Polygon') {
            points = geoJSON.geometry.coordinates[0].map(function(point) {
              return new instance.mapkit.Coordinate(point[1], point[0]);
            });
          }

          // create building shaped overlays
          var bldg = new instance.mapkit.PolygonOverlay(points, {
            style: bldgstyle,
            data: geoJSON.properties
          });
          // add a marker to the selected building
          if (geoJSON.properties.focused) {
            // make apple maps aware that this overlay is selected
            bldg.selected = true;
            // add a red outline to the selected building
            instance.setBuildingFocus(bldg, geoJSON.properties);
          }

          bldg.addEventListener("select", (e) => {
            // add a red outline to the selected building
            instance.setBuildingFocus(bldg, geoJSON.properties);
            // zoom into the selected area
            instance.setFocusArea(geoJSON.properties.lat, geoJSON.properties.long);
            // show the overlay for the selected location
            callback(geoJSON.properties.id);
          });

          bldg.addEventListener('deselect', (e) => {
            // remove any directional polylines if they exist
            instance.removePolylineOverlay();
            // remove the focused styling from the overlay
            instance.setRemoveBuildingFocus(bldg, geoJSON.properties);
            // confirm that the user has deselected everything
            if (mapState.selectedAnnotation === null) {
              callback('');
            }
          });

          // overlay the buildings one by one as they are completed
          // instance.map.addOverlay(bldg);
          return bldg;
        }
      },

      geoJSONDidComplete: (result, geoJSON) => {
        instance.map.addOverlays(result.items);
        this.deepLink();
      },
      geoJSONDidError: function(error, geoJSON) {}
    };
  }

  /**
   * Adds a directions route polyline to the map.
   *
   * Currently this is only used in trip planning.
   */
  setPolylineOverlay(points = []) {
    // start fresh with a new polyline by removing old polyline first
    this.removePolylineOverlay();
    // check to make sure we having something to map
    if (points.length > 0) {
      var coords = points.map((point) => {
        return new this.mapkit.Coordinate(point[0], point[1]);
      });

      var style = new this.mapkit.Style({
        lineWidth: 2,
        lineJoin: "round",
        lineDash: [8, 4],
        strokeColor: "#012169"
      });

      this.directionsPolyline = new this.mapkit.PolylineOverlay(coords, { style: style });
      this.map.addOverlay(this.directionsPolyline);
    }
  }

  /**
   * Removes active polylines on the map.
   *
   * First checks to see if one exists. Then removes.
   */
  removePolylineOverlay() {
    if (this.directionsPolyline) {
      this.map.removeOverlay(this.directionsPolyline);
    }
  }

  /**
   * Moves the focus area of the map to the provided lat/lng cordinates.
   */
  setFocusArea(lat, long) {
    this.map.setRegionAnimated(
      new this.mapkit.CoordinateRegion(
        new this.mapkit.Coordinate(lat, long),
        new this.mapkit.CoordinateSpan(0.014 , 0.014)
      )
    );
  }

  /**
   * Adds the ability to set multiple markers on the map.
   *
   * Marker style will change depending on the location type for the marker.
   */
 setMarkerCollection(locations, callback) {
    let lat,
        long,
        duplicates;

    for (let type in locations) {
      duplicates = [];
      if (type === 'TRANSLOC_STOPS') {
        this.addTranslocRouteData(locations[type], callback);
      }
      else if (type === 'ADA_ROUTES') {
        this.addAdaRouteData(locations[type], callback);
      }
      else {
        locations[type].forEach((location, index) => {
          if (location.geojson === null) {
            lat   = location.lat;
            long  = location.long;
          }
          else {
            lat   = location.geojson.properties.lat;
            long  = location.geojson.properties.long;
          }
          duplicates = this.map.annotations.filter(function(item) {
            return (item.data.id === location.id);
          });
          if (duplicates.length === 0) {
            switch(location.type) {
              case 'Places':
                this.setFocusAreaMarker(
                  location.nameDisplay,
                  lat,
                  long,
                  location.type,
                  location.category,
                  location.id,
                  callback,
                  location.attrOpenNow
                );
                break;
              default:
                this.setFocusAreaMarker(
                  location.nameDisplay,
                  lat,
                  long,
                  location.type,
                  location.category,
                  location.id,
                  callback,
                );
                break;
            }
          }
        });
      }
    }
  }

  collectFilterMachineName(filter) {
    let type = null;
    switch(filter) {
      case 'Academic':
        type = 'ACADEMIC_ADMINISTRATIVE';
        break;
      case 'Housing':
        type = 'HOUSING';
        break;
      case 'Medical':
        type = 'MEDICAL_CENTER';
        break;
      case 'Athletic':
        type = 'ATHLETICS';
        break;
      default:
        break;
    }

    return type;
  }

  updateOverlayColor(filter, clear = false) {
    let type = null;
    switch(filter) {
      case 'ACADEMIC_ADMINISTRATIVE':
        type = 'Academic';
        break;
      case 'HOUSING':
        type = 'Housing';
        break;
      case 'MEDICAL_CENTER':
        type = 'Medical';
        break;
      case 'ATHLETICS':
        type = 'Athletic';
        break;
      default:
        break;
    }

    if (type === null) {
      return true;
    }

    // loop through the overlays and adjust the colors as needed
    for (var i = this.map.overlays.length - 1; i >= 0; i--) {
      if (this.map.overlays[i].data.type === 'Buildings' && this.map.overlays[i].data.category === type) {
        if (clear) {
          this.map.overlays[i].style.fillColor = '#f5f5dc';
          this.map.overlays[i].style.fillOpacity = .8;
        }
        else {
          this.map.overlays[i].style.fillColor = this.map.overlays[i].data.colorHex;
          this.map.overlays[i].style.fillOpacity = 1;
        }
      }
    }

    return false;
  }

  /**
   * Update the moving bus markers on the map.
   */
  updateBusSubscriptionMarkers(data) {
    // attempted to do this with css animations rather than images but could not figure out
    // https://developer.apple.com/documentation/mapkitjs/mapkit/annotation/2973818-appearanceanimation
    // https://drafts.csswg.org/css-animations/#animation

    let markerObject = {
      titleVisibility: 'hidden',
      appearanceAnimation: '1s linear 1s 1 normal forwards spin',
      enabled: false,
      glyphColor: '#fff'
      // data: {id: 123}
    };

    let heading,
        markers = data.map((bus, index)=> {
      // set bus specific details for this marker
      markerObject['title'] = 'Bus #'+bus.callName;
      markerObject['accessibilityLabel'] = markerObject['title'];
      markerObject['color'] = '#' + bus.routeColor;
      // figure out the desired rotation of the image
      heading = bus.heading;
      if (this.map.rotation !== 0) {
        // logic for fixed images usage
        heading = bus.heading + this.map.rotation;
        if (heading > 360) {
          heading = heading - 360;
        }

        // logic for rotating single image, leaving
        // here so that I don't have to figure it
        // out again if I solve the applemaps
        // animation issue

        // find the new north
        // newNorth = 360 - this.map.rotation;
        // // add the bus degress to the newNorth
        // heading = newNorth + bus.heading;
        // if (heading > 360) {
        //   heading = heading - 360;
        // }
      }

      if (heading >= 0 && heading < 45) {
        markerObject['glyphImage'] = { 1: BusIcon22 };
      }
      else if (heading >= 45 && heading < 90) {
        markerObject['glyphImage'] = { 1: BusIcon67 };
      }
      else if (heading >= 90 && heading < 135) {
        markerObject['glyphImage'] = { 1: BusIcon112 };
      }
      else if (heading >= 135 && heading < 180) {
        markerObject['glyphImage'] = { 1: BusIcon157 };
      }
      else if (heading >= 180 && heading < 225) {
        markerObject['glyphImage'] = { 1: BusIcon202 };
      }
      else if (heading >= 225 && heading < 270) {
        markerObject['glyphImage'] = { 1: BusIcon247 };
      }
      else if (heading >= 270 && heading < 315) {
        markerObject['glyphImage'] = { 1: BusIcon292 };
      }
      else if (heading >= 315 && heading < 360) {
        markerObject['glyphImage'] = { 1: BusIcon337 };
      }

      return new this.mapkit.MarkerAnnotation(
        new this.mapkit.Coordinate(bus.location.lat, bus.location.lng),
        markerObject
      );
    });

    // clear existing markers to prevent duplicates
    this.map.removeAnnotations(this.busSubscriptions);
    // set map global storage var
    this.busSubscriptions = markers;
    // add new markers to the map
    this.map.addAnnotations(markers);
  }

  /**
   * Adds a marker annotation to the provided lat/lng coordinates w/ title.
   *
   * Annotation construction options can be found here:
   * https://developer.apple.com/documentation/mapkitjs/annotationconstructoroptions
   *
   * @todo This function has grown widly out of control. Need to break it apart into
   * smaller pieces.
   */
  setFocusAreaMarker(title, lat, long, type, category, id, callback, openStatus, selectedState = false) {
    // define the basic settings for a marker
    let marker,
        markerObject = {
          title: title,
          titleVisibility: 'hidden',
          accessibilityLabel: title,
          enabled: callback ? true : false,
          data: {id: id},
          color: '#666666'
        };

    //console.log('type: ', type, ' | ', 'category: ', category);

    // determine what type of marker to use
    if (type === 'Places' && category === 'Dining') {
      // add the places marker details
      markerObject['glyphColor'] = "#fff";
      markerObject['color'] = openStatus ? "#339898" : "#BBBBBB";
      markerObject['glyphImage'] = { 1: FoodMarker };
      markerObject['selectedGlyphImage'] = { 1: FoodMarker };
    }
    else if (type === 'Places' && category === 'DukeCard') {
      // add the places marker details
      markerObject['glyphColor'] = "#fff";
      markerObject['color'] = openStatus ? "#E9C040" : "#BBBBBB";
      markerObject['glyphImage'] = { 1: DukeCardMarker };
      markerObject['selectedGlyphImage'] = { 1: DukeCardMarker };
    }
    else if (type === 'Places' && category === 'Duke Stores') {
      // add the places marker details
      markerObject['glyphColor'] = "#fff";
      markerObject['color'] = openStatus ? "#E89923" : "#BBBBBB";
      markerObject['glyphImage'] = { 1: DukeStoresMarker };
      markerObject['selectedGlyphImage'] = { 1: DukeStoresMarker };
    }
    else if (type === 'Rooms' && category === 'Lactation room') {
      // add the places marker details
      markerObject['color'] = "#B94A66";
      markerObject['glyphColor'] = '#fff';
      markerObject['glyphImage'] = { 1: LactationMarker };
      markerObject['selectedGlyphImage'] = { 1: LactationMarker };
    }
    else if (type === 'Rooms' && category === 'Performance Venue') {
      // add the places marker details
      markerObject['color'] = "#993399";
      markerObject['glyphColor'] = '#fff';
      markerObject['glyphImage'] = { 1: VenueMarker };
      markerObject['selectedGlyphImage'] = { 1: VenueMarker };
    }
    else if (type === 'Emergency Phones') {
      // add the places marker details
      markerObject['color'] = "#000";
      markerObject['glyphColor'] = "#fff";
      markerObject['glyphImage'] = { 1: EmergencyMarker };
      markerObject['selectedGlyphImage'] = { 1: EmergencyMarker };
      markerObject['title'] = 'Emergency Phone';
    }
    else if (type === 'Buildings' && category === 'Housing') {
      // add the places marker details
      markerObject['color'] = "#E89923";
      markerObject['glyphColor'] = "#fff";
      markerObject['glyphImage'] = { 1: ResidentialMarker };
      markerObject['selectedGlyphImage'] = { 1: ResidentialMarker };
    }
    else if (type === 'Buildings' && category === 'Medical Center') {
      // add the places marker details
      markerObject['color'] = "#339898";
      markerObject['glyphColor'] = "#fff";
      markerObject['glyphImage'] = { 1: MedicalMarker };
      markerObject['selectedGlyphImage'] = { 1: MedicalMarker };
    }
    else if (type === 'Buildings' && category === 'Athletics') {
      // add the places marker details
      markerObject['color'] = "#00539B";
      markerObject['glyphColor'] = "#fff";
      markerObject['glyphImage'] = { 1: AthleticMarker };
      markerObject['selectedGlyphImage'] = { 1: AthleticMarker };
    }
    else if (type === 'Parking' && category === 'Visitor') {
      // add the places marker details
      markerObject['color'] = "#81B70D";
      markerObject['glyphColor'] = "#fff";
      markerObject['glyphImage'] = { 1: ParkingVisitor };
      markerObject['selectedGlyphImage'] = { 1: ParkingVisitor };
    }
    else if (type === 'Parking' && category === 'Permit') {
      // add the places marker details
      markerObject['color'] = "#012169";
      markerObject['glyphColor'] = "#fff";
      markerObject['glyphImage'] = { 1: ParkingPermit };
      markerObject['selectedGlyphImage'] = { 1: ParkingPermit };
    }
    else if (type === 'Parking' && category === 'Motorcycles') {
      // add the places marker details
      markerObject['color'] = "#1D6363";
      markerObject['glyphColor'] = "#fff";
      markerObject['glyphImage'] = { 1: ParkingMotorcycle };
      markerObject['selectedGlyphImage'] = { 1: ParkingMotorcycle };
    }
    else if (type === 'Parking' && category === 'Bicycles') {
      // add the places marker details
      markerObject['color'] = "#C84E00";
      markerObject['glyphColor'] = "#fff";
      markerObject['glyphImage'] = { 1: ParkingBicycle };
      markerObject['selectedGlyphImage'] = { 1: ParkingBicycle };
    }
    else if (type === 'Buildings' && category === 'Under Construction') {
      // add the places marker details
      markerObject['color'] = "#E36310";
      markerObject['glyphColor'] = "#fff";
      markerObject['glyphImage'] = { 1: ConstructionMarker };
      markerObject['selectedGlyphImage'] = { 1: ConstructionMarker };
    }
    else if (type === 'ADA Entrances' && category === 'ADA Entrances') {
      // add the places marker details
      markerObject['color'] = "#005587";
      markerObject['glyphColor'] = "#fff";
      markerObject['glyphImage'] = { 1: AdaEntranceMarker };
      markerObject['selectedGlyphImage'] = { 1: AdaEntranceMarker };
    }
    else if (type === 'Parking' && category === 'Mix') {
      markerObject['url'] = { 1: ParkingMixed, 2: ParkingMixed};
      markerObject['enabled'] = true;
      markerObject['size'] = { width: 35, height: 35 };
      markerObject['calloutOffset'] = new DOMPoint(0, -80);
      delete markerObject.titleVisibility;
      markerObject['callout'] = {
        calloutElementForAnnotation: function(annotation) {
            var element = document.createElement("div");
            element.className = "img-annotation-callout";
            var title = element.appendChild(document.createElement("span"));
            title.textContent = annotation.title;

            return element;
          }
      };

      marker = new this.mapkit.ImageAnnotation(
        new this.mapkit.Coordinate(lat, long), markerObject
      );
    }

    if (category !== 'Mix') {
      marker = new this.mapkit.MarkerAnnotation(
        new this.mapkit.Coordinate(lat, long), markerObject
      );
    }

    if (selectedState) {
      marker.selected = true;
      marker.titleVisibility = 'adaptive';
    }

    // add a click event handler
    marker.addEventListener("select", (e) => {
      if (category === 'Mix') {
        e.target.url = { 1: ParkingMixedActive, 2: ParkingMixedActive };
        e.target.size = { width: 50, height: 50 };
      }
      // show the title
      e.target.titleVisibility = 'adaptive';
      // set this area as in focus, showing overlay for the selected location
      callback(id);
    });
    // add a click event handler
    marker.addEventListener("deselect", (e) => {
      if (category === 'Mix') {
        e.target.url = { 1: ParkingMixed, 2: ParkingMixed };
        e.target.size = { width: 35, height: 35 };
      }
      // remove any directional polylines if they exist
      this.removePolylineOverlay();
      // hide the title
      e.target.titleVisibility = 'hidden';
      // confirm that the user has deselected everything
      if (this.map.selectedAnnotation === null && this.map.selectedOverlay === null) {
        callback('');
      }
    });

    // add the marker
    this.map.addAnnotation(marker);
  }

  /**
   * Applies special overlay formating that represents a selected overlay.
   */
  setBuildingFocus(bldg, data) {
    bldg.style.strokeColor = 'red';
    bldg.style.lineWidth = 3;
    bldg.style.fillColor = data.colorHex;
  }

  /**
   * Removes special overlay formating that represents a selected overlay.
   */
  setRemoveBuildingFocus(bldg, data) {
    bldg.style.fillColor = '#f5f5dc';
    if (this.map.hasOwnProperty("filters")) {
      let filter = this.collectFilterMachineName(data.category);
      if (this.map.filters[filter]) {
        // check if this filter is relevant to this building
        bldg.style.fillColor = data.colorHex;
      }
    }
    bldg.style.strokeColor = '#000';
    bldg.style.lineWidth = .5;
  }
}
