import React, { Component } from 'react';
import './SearchResults.css';

class SearchResults extends Component {
  componentDidMount() {
    document.getElementById('results-focus').focus();
  }

  render() {
    let searchResults = [(<li>No search results.</li>)];
    if (this.props.properties.hasOwnProperty('results')) {
      searchResults = this.props.properties.results.map((result, index) => {
        return (
          <li key={ index }>
            <a
              href="/#"
              tabIndex="0"
              aria-label={ result.nameDisplay }
              onMouseOver={ this.onHover.bind(this, result.id) }
              onMouseOut={ this.onHover.bind(this, result.id) }
              onClick={ this.onClick.bind(this, result.id) }>
              { result.nameDisplay }
            </a>
          </li>
        );
      });
    }

    return (
      <div>
        <div className="container">
          <h2>Search Results</h2>
          <ul
            id="results-focus"
            className="search-results"
            tabIndex="-1">
            { searchResults }
          </ul>
        </div>
      </div>
    );
  }

  onHover(id, e) {
    if (this.props.hasOwnProperty('annotationHoverAction')) {
      this.props.annotationHoverAction(id);
    }
  }

  onClick(id, e) {
    e.preventDefault();
    this.props.updateFocus(id);

    return false;
  }

}

export default SearchResults;
